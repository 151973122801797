import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { constants } from '../../constants';
import { useHistory } from 'react-router-dom';
import { Image } from '../../components';
import { setActiveMenuName } from '../../actions/sidebar';
import { useDispatch } from 'react-redux';
import { getTheProfileImage } from '../../utility/common';

const HeaderDropdown = ({ profile, logout }) => {
  const { profilePlaceholder, logOutPlaceholder } = constants.header;
  const dispatch = useDispatch();
  const history = useHistory();
  const navigateToProfilePage = () => {
    dispatch(setActiveMenuName('profile'));
    history.push('/profile');
  };

  let userType = localStorage.getItem('type');

  let profileImageData = getTheProfileImage(profile, 'profile_img_path');
  return (
    <Dropdown>
      <Dropdown.Toggle variant="default" id="dropdown-basic">
        <Image
          key={profile.id + Math.random()}
          className={'profile-image'}
          src={profileImageData || '/images/user.png'}
        />
        <span className="user-name">
          {profile &&
            profile?.attributes?.profile &&
            profile?.attributes?.profile?.name + ' '}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu align={'right'}>
        {userType != "organiser" && <Dropdown.Item onClick={navigateToProfilePage}>
          <i className="fas fa-user-cog" />
          {profilePlaceholder}
        </Dropdown.Item>}
        <Dropdown.Item onClick={logout}>
          <i className="fas fa-sign-in-alt fa-rotate-180" />
          {logOutPlaceholder}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderDropdown;
