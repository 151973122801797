import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from './button';

const Input = ({
  infoText,
  controlId,
  label,
  type,
  placeholder,
  error,
  showError,
  inputRef,
  name,
  disabled,
  readOnly,
  onChange,
  onBlur,
  buttonLabel,
  isLoading,
  isButtonDisabled,
  onButtonClick,
  isRequired,
  value,
  isControlled,
  iconClass,
  buttonVariant,
  hasButton,
  buttonType,
  prependText,
  hintText,
  registeredEvents,
  isPassword,
  accept,
  autoFocus,
  tabindex,
  fieldValues,
  onPaste,
  id,
  min,
  onClick,
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  const managePasswordVisibility = () => {
    setHidePassword(!hidePassword);
  };

  const showPassword = () => {
    if (isPassword) {
      return (
        <i
          className={
            hidePassword ? 'fas fa-eye-slash' : 'fa fa-eye show-icon-in-textbox'
          }
          aria-hidden="true"
          onClick={managePasswordVisibility}
        />
      );
    }
    return null;
  };

  return (
    <Form.Group
      // tabindex={tabindex}
      className={iconClass && !onButtonClick ? 'with-icon' : ''}
      tabIndex={tabindex}
      autoFocus={autoFocus}
      controlId={controlId}>
      {label && (<>
        <Form.Label>
          {label}
          {isRequired && <span className="required"> *</span>}
          {hintText && <span className="input-example">{hintText}</span>}
        </Form.Label>
        {infoText && <small>{<b>({infoText})</b>}</small>}</>
      )}
      <InputGroup>
        {isControlled ? (
          <Form.Control
            name={name}
            type={type}
            id={id}
            onPaste={onPaste}
            placeholder={placeholder}
            // ref={inputRef}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            accept={accept}
            autoFocus={autoFocus}
            min={min}
            onClick={onClick}
          />
        ) : (
          <Form.Control
            {...(registeredEvents || {})}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            accept={accept}
            autoFocus={autoFocus}
            id={id}
            onClick={onClick}
            min={min}
            onPaste={onPaste}
            // ref={inputRef}
            type={isPassword && !hidePassword ? 'text' : type}
          />
        )}
        {isPassword && fieldValues && fieldValues.length > 0 && (
          <div className="showIcon">{showPassword()}</div>
        )}
        {(buttonLabel || iconClass) && (
          <InputGroup.Append>
            {hasButton && (
              <Button
                disabled={isButtonDisabled}
                isLoading={isLoading}
                label={buttonLabel}
                onClick={onButtonClick}
                variant={buttonVariant}
                iconClass={iconClass}
                type={buttonType}
              />
            )}
            {!hasButton && iconClass && <i className={iconClass} />}
          </InputGroup.Append>
        )}
        {prependText && <span className="numbers">{prependText}</span>}
      </InputGroup>
      {showError && error && (
        <Form.Text className="error-text">{error}</Form.Text>
      )}
    </Form.Group>
  );
};

export default Input;
