import { decrementLoaderCount, incrementLoaderCount } from './loader';
import { getGenreList, getEditGenreDetails } from '../apis/genreManagement';

export const GET_GENRE_LIST = 'GET_GENRE_LIST';
export const GET_GENRES_DATA = 'GET_GENRES_DATA';

export const getGenreListData = (body) => (dispatch) => {
  dispatch(incrementLoaderCount(true));
  return getGenreList(body)
    .then((res) => {
      if (res?.data?.status) {
        const genreList = {
          items: (res?.data && res?.data?.data?.genres) || [],
          totalItemCount: (res?.data && res?.data?.records) || 0,
          totalPages: (res?.data && res?.data?.total_pages) || 0,
        };
        dispatch(setGenresList(genreList));
      }
      dispatch(decrementLoaderCount(false));

      return res?.data;
    })
    .catch(() => dispatch(decrementLoaderCount(false)));
};

export const setGenresList = (data) => ({
  type: GET_GENRE_LIST,
  payload: data,
});

// export const getEditGenre = (id) => (dispatch) => {
//   dispatch(incrementLoaderCount(true));
//   return getEditGenreDetails(id)
//     .then((res) => {
//
//       if (res?.data?.status) {
//         dispatch(getData('editGenresData', res.data.genre || {}));
//       }
//       dispatch(decrementLoaderCount(false));
//       return res.data;
//     })
//     .catch(() => dispatch(decrementLoaderCount(false)));
// };

// export const getData = (key, data) => ({
//   type: GET_GENRES_DATA,
//   key: key,
//   payload: data,
// });
