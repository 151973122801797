import React, { useRef, useEffect } from 'react';
import { Modal, Input } from '../index';
import { Form } from 'react-bootstrap';
import { constants } from '../../constants';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from '../../schema/changePassword';

const ChangePasswordModal = ({
  isModalVisible,
  toggleModal,
  onChangePassword,
  isLoading,
}) => {
  const innerRef = useRef();
  const {
    title,
    confirmPasswordPlaceholder,
    newPasswordPlaceholder,
    oldPasswordPlaceholder,
    footerButtons,
  } = constants.profile.changePasswordModal;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => document.getElementById('password-input').focus(), []);

  const modalFooterButtons = [
    {
      label: footerButtons.cancel,
      className: 'cancel-btn',
      isLoading: false,
      isDisabled: false,
      onClick: toggleModal,
      variant: 'secondary',
    },
    {
      label: footerButtons.change,
      className: 'change-btn',
      isLoading: isLoading,
      isDisabled: isLoading,
      onClick: handleSubmit(onChangePassword),
      variant: 'success',
    },
  ];

  const values = getValues();

  return (
    <Modal
      title={title}
      isModalVisible={isModalVisible}
      buttons={modalFooterButtons}
      toggleModal={toggleModal}
      modalClass="change-password-modal">
      <Form onSubmit={handleSubmit(onChangePassword)}>
        <Input
          controlId="formOldPassword"
          type="password"
          error={errors?.current_password?.message}
          showError={true}
          registeredEvents={register('current_password')}
          isRequired={true}
          label={oldPasswordPlaceholder}
          autoFocus={true}
          fieldValues={values.current_password}
          isPassword
          inputRef={innerRef}
          id={'password-input'}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <Input
          controlId="formNewPassword"
          type="password"
          error={errors?.password?.message}
          showError={true}
          registeredEvents={register('password')}
          isRequired={true}
          label={newPasswordPlaceholder}
          isPassword
          autoFocus={false}
          fieldValues={values.password}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <Input
          controlId="formConfirmPassword"
          type="password"
          error={errors?.password_confirmation?.message}
          showError={true}
          registeredEvents={register('password_confirmation')}
          isRequired={true}
          autoFocus={false}
          label={confirmPasswordPlaceholder}
          fieldValues={values.password_confirmation}
          isPassword
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
