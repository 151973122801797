import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState, useRef, useEffect } from 'react';
import toast from 'react-hot-toast';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { matchPath, useLocation } from 'react-router-dom';

export const getCurrentTimeZone = () => {
  const currentTime = new Date();
  const timezoneOffsetInMinutes = currentTime.getTimezoneOffset();

  // Convert the offset to hours and minutes
  const hoursOffset = Math.floor(Math.abs(timezoneOffsetInMinutes) / 60);
  const minutesOffset = Math.abs(timezoneOffsetInMinutes) % 60;

  // Determine if the offset is positive or negative
  const sign = timezoneOffsetInMinutes > 0 ? '-' : '+';

  // Construct the timezone string
  const timezone = `${sign}${hoursOffset
    .toString()
    .padStart(2, '0')}:${minutesOffset.toString().padStart(2, '0')}`;

  return timezone;
};
export const getTimeZone = [
  {
    value: '-12:00',
    label: 'Etc/-12',
  },
  {
    value: '-11:00',
    label: 'Etc/GMT-11',
  },
  {
    value: '-11:00',
    label: 'Pacific/Midway',
  },
  {
    value: '-10:00',
    label: 'America/Adak',
  },
  {
    value: '-09:00',
    label: 'America/Anchorage',
  },
  {
    value: '-09:00',
    label: 'Pacific/Gambier',
  },
  {
    value: '-08:00',
    label: 'America/Dawson_Creek',
  },
  {
    value: '-08:00',
    label: 'America/Ensenada',
  },
  {
    value: '-08:00',
    label: 'America/Los_Angeles',
  },
  {
    value: '-07:00',
    label: 'America/Chihuahua',
  },
  {
    value: '-07:00',
    label: 'America/Denver',
  },
  {
    value: '-06:00',
    label: 'America/Belize',
  },
  {
    value: '-06:00',
    label: 'America/Cancun',
  },
  {
    value: '-06:00',
    label: 'America/Chicago',
  },
  {
    value: '-06:00',
    label: 'Chile/EasterIsland',
  },
  {
    value: '-05:00',
    label: 'America/Bogota',
  },
  {
    value: '-05:00',
    label: 'America/Havana',
  },
  {
    value: '-05:00',
    label: 'America/New_York',
  },
  {
    value: '-04:30',
    label: 'America/Caracas',
  },
  {
    value: '-04:00',
    label: 'America/Campo_Grande',
  },
  {
    value: '-04:00',
    label: 'America/Glace_Bay',
  },
  {
    value: '-04:00',
    label: 'America/Goose_Bay',
  },
  {
    value: '-04:00',
    label: 'America/Santiago',
  },
  {
    value: '-04:00',
    label: 'America/La_Paz',
  },
  {
    value: '-03:00',
    label: 'America/Argentina/Buenos_Aires',
  },
  {
    value: '-03:00',
    label: 'America/Montevideo',
  },
  {
    value: '-03:00',
    label: 'America/Araguaina',
  },
  {
    value: '-03:00',
    label: 'America/Godthab',
  },
  {
    value: '-03:00',
    label: 'America/Miquelon',
  },
  {
    value: '-03:00',
    label: 'America/Sao_Paulo',
  },
  {
    value: '-03:30',
    label: 'America/St_Johns',
  },
  {
    value: '-02:00',
    label: 'America/Noronha',
  },
  {
    value: '-01:00',
    label: 'Atlantic/Cape_Verde',
  },
  {
    value: '+01:00',
    label: 'Africa/Algiers',
  },
  {
    value: '+01:00',
    label: 'Africa/Windhoek',
  },
  {
    value: '+01:00',
    label: 'Atlantic/Azores',
  },
  {
    value: '+01:00',
    label: 'Atlantic/Stanley',
  },
  {
    value: '+01:00',
    label: 'Europe/Amsterdam',
  },
  {
    value: '+01:00',
    label: 'Europe/Belgrade',
  },
  {
    value: '+01:00',
    label: 'Europe/Brussels',
  },
  {
    value: '+02:00',
    label: 'Africa/Cairo',
  },
  {
    value: '+02:00',
    label: 'Africa/Blantyre',
  },
  {
    value: '+02:00',
    label: 'Asia/Beirut',
  },
  {
    value: '+02:00',
    label: 'Asia/Damascus',
  },
  {
    value: '+02:00',
    label: 'Asia/Gaza',
  },
  {
    value: '+02:00',
    label: 'Asia/Jerusalem',
  },
  {
    value: '+03:00',
    label: 'Africa/Addis_Ababa',
  },
  {
    value: '+03:00',
    label: 'Asia/Riyadh89',
  },
  {
    value: '+03:00',
    label: 'Europe/Minsk',
  },
  {
    value: '+03:30',
    label: 'Asia/Tehran',
  },
  {
    value: '+04:00',
    label: 'Asia/Dubai',
  },
  {
    value: '+04:00',
    label: 'Asia/Yerevan',
  },
  {
    value: '+04:00',
    label: 'Europe/Moscow',
  },
  {
    value: '+04:30',
    label: 'Asia/Kabul',
  },
  {
    value: '+05:00',
    label: 'Asia/Tashkent',
  },
  {
    value: '+05:30',
    label: 'Asia/Kolkata',
  },
  {
    value: '+05:45',
    label: 'Asia/Katmandu',
  },
  {
    value: '+06:00',
    label: 'Asia/Dhaka',
  },
  {
    value: '+06:00',
    label: 'Asia/Yekaterinburg',
  },
  {
    value: '+06:30',
    label: 'Asia/Rangoon',
  },
  {
    value: '+07:00',
    label: 'Asia/Bangkok',
  },
  {
    value: '+07:00',
    label: 'Asia/Novosibirsk',
  },
  {
    value: '+08:00',
    label: 'Etc/+8',
  },
  {
    value: '+08:00',
    label: 'Asia/Hong_Kong',
  },
  {
    value: '+08:00',
    label: 'Asia/Krasnoyarsk',
  },
  {
    value: '+08:00',
    label: 'Australia/Perth',
  },
  {
    value: '+08:45',
    label: 'Australia/Eucla',
  },
  {
    value: '+09:00',
    label: 'Asia/Irkutsk',
  },
  {
    value: '+09:00',
    label: 'Asia/Seoul',
  },
  {
    value: '+09:00',
    label: 'Asia/Tokyo',
  },
  {
    value: '+09:30',
    label: 'Australia/Adelaide',
  },
  {
    value: '+09:30',
    label: 'Australia/Darwin',
  },
  {
    value: '+09:30',
    label: 'Pacific/Marquesas',
  },
  {
    value: '+10:00',
    label: 'Etc/+10',
  },
  {
    value: '+10:00',
    label: 'Australia/Brisbane',
  },
  {
    value: '+10:00',
    label: 'Australia/Hobart',
  },
  {
    value: '+10:00',
    label: 'Asia/Yakutsk',
  },
  {
    value: '+10:30',
    label: 'Australia/Lord_Howe',
  },
  {
    value: '+11:00',
    label: 'Asia/Vladivostok',
  },
  {
    value: '+11:30',
    label: 'Pacific/Norfolk',
  },
  {
    value: '+12:00',
    label: 'Etc/+12',
  },
  {
    value: '+12:00',
    label: 'Asia/Anadyr',
  },
  {
    value: '+12:00',
    label: 'Asia/Magadan',
  },
  {
    value: '+12:00',
    label: 'Pacific/Auckland',
  },
  {
    value: '+12:45',
    label: 'Pacific/Chatham',
  },
  {
    value: '+13:00',
    label: 'Pacific/Tongatapu',
  },
  {
    value: '+14:00',
    label: 'Pacific/Kiritimati',
  },
];
/** This method used for show the toast message in screen,
 * @param message
 */
export const showToast = (message) =>
  message &&
  toast(message, {
    position: 'top-center',
    style: {
      background: '#333',
      color: '#fff',
    },
  });

/** This method used for formate date and time */
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const formatDate = (date) => date && dayjs(date).format('DD-MM-YYYY');
export const formatTime = (time) => time && dayjs(time).format('hh:mm A');

export const getStringDateToDate = (date) => {
  return dayjs(date);
};

export const formatDateAndTime1 = (date) => {
  let dateType = date ? typeof date : undefined;
  let newDate = dateType ? (dateType != 'string' ? date : null) : date;

  if (date && dateType == 'string') {
    let newDateObj = date.split('T');
    let newDate = new Date(newDateObj[0]);
    let min = 0;
    let hrs = 0;
    if (newDateObj[1]) {
      hrs = newDateObj[1].split(':')[0];
      min = newDateObj[1].split(':')[1];
    }
    newDate.setHours(0, 0);
    newDate.setHours(hrs, min);
    return newDate;
  }
  // console.log("New Date",newDate)
  return newDate;
};

export const formatDateAndTime = (date) =>
  date && dayjs(date).format('DD-MM-YYYY hh:mm A');

export const formatDateAndTimeNew = (date) =>
  date && dayjs.utc(date).format('DD-MM-YYYY hh:mm A');

export const formatDateBySpecifiedFormat = (date, format) =>
  date && format && dayjs(date).format(format);

export const fromNow = (date) => dayjs(date).fromNow();

export const timeTo = (date) => {
  let returnValue = '-';
  if (date) {
    let today = dayjs();
    let checkingDate = dayjs(date);
    let diff = dayjs.duration(checkingDate.diff(today));

    let hoursDiff = parseInt(diff.asHours(), 10);

    let minutesDiff = parseInt(diff.asMinutes(), 10) % 60;

    if (hoursDiff > 24) {
      returnValue = capitalize(dayjs(date).toNow(true));
    } else {
      returnValue = `${hoursDiff} ${
        hoursDiff > 1 ? 'hours' : 'hour'
      } ${minutesDiff} ${minutesDiff > 1 ? 'minutes' : 'minute'}`;
    }
  }
  return returnValue;
};

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const titleCase = (s) => {
  let sentence = s && s.split('_');
  sentence = sentence && sentence.map((se) => capitalize(se));

  return sentence && sentence.join(' ');
};

/** This method used to getting the file name from url */
export const getFileNameFromURL = (url) => {
  let tempName =
    (url && url.split('/').pop().split('#')[0].split('?')[0]) || '';
  return tempName.replace(/%20/g, ' ');
};

export const getStringWithDays = (s) => {
  let modifiedString = s || '-';
  if (modifiedString > 1) {
    modifiedString += ' days';
  } else if (modifiedString === 1) {
    modifiedString += ' day';
  }
  return modifiedString;
};

export const getStringWithHours = (s) => {
  let modifiedString = s || '-';
  if (modifiedString > 1) {
    modifiedString += ' hours';
  } else if (modifiedString === 1) {
    modifiedString += ' hour';
  }
  return modifiedString;
};

export const getFileExtensionFromName = (name) => {
  if (name) {
    let nameArray = name.split('.') || [];
    let extensionArray =
      (nameArray[nameArray.length - 1] &&
        nameArray[nameArray.length - 1].split('?')) ||
      [];
    return extensionArray[0] || '';
  }
};

export const removeAllSpacesFromString = (s) => s && s.replace(/ /g, '');

/** This method used for get the token in to the local Storage */
export const getToken = async () => {
  try {
    const token = await localStorage.getItem('TOKEN');
    if (token !== null) {
      return token;
    }
  } catch (e) {
    return e;
  }
};

/** This method used for save the token in to the local Storage */
export const saveToken = async (token) => {
  try {
    await localStorage.setItem('TOKEN', token);
  } catch (e) {
    return e;
  }
};

/** This method used for remove the token in to the local Storage */
export const removeToken = async () => {
  try {
    await localStorage.removeItem('TOKEN');
    await localStorage.removeItem('userId');
    localStorage.setItem('SHOW_TOAST', false);
  } catch (e) {
    return e;
  }
};

export const getFormattedDropDownListData = (optionsData, label, value) =>
  optionsData && optionsData.length > 0
    ? optionsData.map((item) => ({
        label: item[label],
        value: item[value],
      }))
    : [];

export const getSelectedOrganiser = (listData, label, value, selected_id) => {
  let selectedItem = undefined;
  listData?.items && listData?.items?.length > 0
    ? listData?.items?.map((item) => {
        if (item[value] == selected_id) {
          selectedItem = {
            label: item?.attributes[label],
            value: item[value],
          };
        }
      })
    : [];

  return selectedItem;
};

export const getSelectedAge = (listData, label, value, selected_id) => {
  let selectedItem = undefined;
  listData && listData?.length > 0
    ? listData?.map((item) => {
        if (item[value] == selected_id) {
          selectedItem = {
            label: item[label],
            value: item[value],
          };
        }
      })
    : [];

  return selectedItem;
};

export const AgeDrpList = [
  { label: '12+', value: '12+' },
  { label: '16+', value: '16+' },
  { label: '18+', value: '18+' },
  { label: 'No age Restriction', value: 'No age Restriction' },
];

export const getOrganiserDropDownListData = (listData, label, value) =>
  listData?.items && listData?.items?.length > 0
    ? listData?.items?.map((item) => ({
        label: item?.attributes[label],
        value: item[value],
      }))
    : [];

export const getSearchDropDownListData = (listData, label, value) =>
  listData?.items && listData?.items?.length > 0
    ? listData?.items?.map((item) => ({
        label: item?.attributes[label],
        value: item?.attributes[value],
      }))
    : [];

/** Using this method we call the callback method when state is change */
export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // mutable ref to store current callback

  const setStateCallback = (state, cb) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  };

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};

/** Using this we get the header title */
export const getHeaderTitle = (path) => {
  switch (path) {
    case '/profile':
    case '/add-profile':
      return 'Manage Profile';
    case '/dashboard':
      return 'Dashboard';
    case '/add-new-user':
      return 'Add User';
    case '/creator':
    case '/add-creator':
      return 'User Management';
    case '/aficionado':
    case '/add-aficionado':
      return 'Aficionado Management';
    case './edit-user':
      return 'Edit User';
    case '/genre':
    case '/add-genre':
    case '/edit-genre':
      return 'Genre Management';
    case '/sendInvitation':
      return 'Send Invitation';
    case '/forgot-password':
      return 'ForgotPassword';
    case '/reset-password':
      return 'ResetPassword';
    case '/manage-users':
      return 'Manage Users';
    case '/events':
      return 'Event Management';
    case '/organisers':
      return 'Organiser Management';
    default:
      return 'Yet to be set';
  }
};

/** This method used for add the active class when click on sidebar menu */
export const getSidebarMenuClasses = (path) => {
  let sidebarMenuClasses = {
    dashboard: '',
    manageUsers: '',
  };
  switch (path) {
    case '/dashboard':
      return (sidebarMenuClasses.dashboard = 'active');
    case '/add-new-user':
    case '/manage-users':
    case '/edit-user':
      return (sidebarMenuClasses.manageUsers = 'active');
    default: {
      return sidebarMenuClasses;
    }
  }
};

/** This method used for getting the API url*/
export const getBaseURL = () => {
  return 'https://6jw7x13lu8.execute-api.us-east-1.amazonaws.com/Prod/api/v1/';
  switch (true) {
    case !process.env.REACT_APP_ENV:
      return process.env.REACT_APP_LOCAL_URL;
    case process.env.REACT_APP_ENV === 'staging':
      return process.env.REACT_APP_STAGING_URL;
    case process.env.REACT_APP_ENV === 'staging-demo':
      return process.env.REACT_APP_STAGING_DEMO_URL;
    case process.env.REACT_APP_ENV === 'production':
      return process.env.REACT_APP_PRODUCTION_URL;
    default: {
      return;
    }
  }
};

/** This method used for getting the image API url*/
export const getBaseURLForImage = () => {
  switch (true) {
    case !process.env.REACT_APP_ENV:
      return process.env.REACT_APP_LOCAL_IMG;
    case process.env.REACT_APP_ENV === 'staging':
      return process.env.REACT_APP_STAGING_IMG;
    case process.env.REACT_APP_ENV === 'staging-demo':
      return process.env.REACT_APP_STAGING_DEMO_IMG;
    case process.env.REACT_APP_ENV === 'production':
      return process.env.REACT_APP_PRODUCTION_IMG;
    default: {
      return;
    }
  }
};

/** Used in listCreator and listAficionado component */
export const convertTemplateDataFormData = (obj) => {
  var formData = new FormData();
  formData.append('name', obj.name);
  formData.append('bio', obj.bio);
  formData.append('contactNo', obj.contactNo);
  formData.append('location', obj.location);
  formData.append('occupation', obj.occupation);
  formData.append('experience', obj.experience);
  formData.append('passion', obj.passion);
  formData.append('website', obj.website);
  return formData;
};

/** convert the Profile data component */
export const convertProfileDataFormData = (obj) => {
  var formData = new FormData();
  obj.firstName &&
    formData.append('user[profile_attributes][name]', obj.firstName);
  obj.bio && formData.append('user[profile_attributes][bio]', obj.bio);
  obj.location &&
    formData.append('user[profile_attributes][location]', obj.location);
  obj.occupation &&
    formData.append('user[profile_attributes][occupation]', obj.occupation);
  obj.experience &&
    formData.append('user[profile_attributes][experience]', obj.experience);
  obj.website &&
    formData.append('user[profile_attributes][website]', obj.website);
  typeof obj.profile_img === 'object' &&
    formData.append('user[profile_attributes][profile_img]', obj.profile_img);
  typeof obj.cover_img === 'object' &&
    formData.append('user[profile_attributes][cover_img]', obj.cover_img);
  return formData;
};

/** Used in Invitation.js component */
export const invitationFromData = (obj) => {
  var formData = new FormData();
  formData.append('user[email]', obj.email);
  formData.append('user[role]', obj.role);
  return formData;
};

//use in addProfile.js component
export const addProfileFormData = (obj) => {
  var formData = new FormData();
  obj.name && formData.append('name', obj.name);
  obj.contactNo && formData.append('contactNo', obj.contactNo);
  obj.location && formData.append('location', obj.location);
  return formData;
};

/** Used in Remember me functionality */
export const setEmailAddressInLocalStorage = (email, rememberMe) => {
  localStorage.setItem('username', email);
  localStorage.setItem('isChecked', rememberMe);
};

/** used to convert genres data to form data*/
export const convertGenreDataFormData = (obj) => {
  var formData = new FormData();
  obj.type && formData.append('genre[name]', obj.type);
  typeof obj.img === 'object' && formData.append('genre[img]', obj.img);
  return formData;
};

/** used to convert genres data to form data*/
export const convertOrganiserFormData = (obj) => {
  var formData = new FormData();
  obj.name && formData.append('organiser[name]', obj.name);
  obj.about && formData.append('organiser[about]', obj.about);
  obj.phone && formData.append('organiser[phone]', obj.phone);
  obj.email && formData.append('organiser[email]', obj.email);
  obj.password && formData.append('organiser[password]', obj.password);
  obj.confirmPassword &&
    formData.append('organiser[password_confirmation]', obj.confirmPassword);
  typeof obj.logo === 'object' && formData.append('organiser[logo]', obj.logo);

  return formData;
};

/** used to convert genres data to form data*/
export const convertEventFormData = (obj, state, method) => {
  var formData = new FormData();
  obj.body && formData.append('event[body]', obj.body);
  obj.age && formData.append('event[age_restriction]', obj.age);
  obj.title && formData.append('event[title]', obj.title);
  obj.price && formData.append('event[price]', obj.price);
  obj.currency && formData.append('event[currency]', obj.currency);
  obj.eventType && formData.append('event[event_type]', obj.eventType);
  obj.lat && formData.append('event[lat]', obj.lat);
  obj.long && formData.append('event[long]', obj.long);
  obj.organiser && formData.append('event[organiser_id]', obj.organiser);
  obj.personCapacity &&
    formData.append('event[person_capacity]', obj.personCapacity);
  obj.soldTickets && formData.append('event[soldTickets]', obj.soldTickets);
  if (method && state && state.endTime) {
    formData.append('event[end_time]', state.endTime);
  } else {
    formData.append('event[end_time]', obj.endTime);
  }
  if (method && state && state.startTime) {
    formData.append('event[start_time]', state.startTime);
  } else {
    formData.append('event[start_time]', obj.startTime);
  }
  if (method && state && state.saleTicketTime) {
    formData.append('event[ticket_sale_start_time]', state.saleTicketTime);
  } else {
    obj &&
      obj.ticketSaleStartTime &&
      formData.append('event[ticket_sale_start_time]', obj.ticketSaleStartTime);
  }
  obj.typeOfTicket &&
    formData.append('event[type_of_ticket]', obj.typeOfTicket);
  obj.venue && formData.append('event[venue_city]', obj.venue);
  obj.venueAddress && formData.append('event[venue_address]', obj.venueAddress);
  obj.language && formData.append('event[event_language]', obj.language);
  obj.country && formData.append('event[country]', obj.country);
  obj.locationUrl &&
    formData.append('event[venue_location_url]', obj.locationUrl);
  obj.artist && formData.append('event[artist]', obj.artist);
  obj.offset_time && formData.append('event[offset_time]', obj.offset_time);
  obj.cover_img_path &&
    typeof obj.cover_img_path === 'object' &&
    formData.append('event[cover_img]', obj.cover_img_path);
  obj.event_list_img_path &&
    typeof obj.event_list_img_path === 'object' &&
    formData.append('event[event_list_img]', obj.event_list_img_path);
  obj.event_slidder_img_path &&
    typeof obj.event_slidder_img_path === 'object' &&
    formData.append('event[event_slidder_img]', obj.event_slidder_img_path);
  obj.ticket_banner_img_path &&
    typeof obj.ticket_banner_img_path === 'object' &&
    formData.append('event[ticket_banner_img]', obj.ticket_banner_img_path);

  return formData;
};

export const changePasswordFormatData = (obj) => {
  var formData = new FormData();
  formData.append('user[current_password]', obj.current_password);
  formData.append('user[password]', obj.password);
  formData.append('user[password_confirmation]', obj.password_confirmation);
  return formData;
};

export const resetPasswordFormatData = (obj, token) => {
  var formData = new FormData();
  formData.append('user[confirm_password]', obj.confirm_password);
  formData.append('user[password]', obj.password);
  formData.append('user[reset_password_token]', token[2]);
  return formData;
};

/** This method used for getting the role data in to the profile state */
export const getUserRole = (profile) => {
  let role = '';
  if (
    profile &&
    profile.attributes &&
    Object.keys(profile.attributes).length > 0
  ) {
    const { roles } = profile.attributes || {};

    if (roles) {
      roles.length > 0 &&
        roles.map((userRole) => {
          if (userRole.name === 'Admin') {
            role = userRole.name;
          }
        });
    } else {
      role = profile.type;
    }
  }

  return role;
};

export const saveRole = (profile) => {
  let role = getUserRole(profile);
  localStorage.setItem('role', role.toLowerCase());
};

export const saveUserType = (type) => {
  localStorage.setItem('type', type.toLowerCase());
};

export const saveUserId = (id) => {
  localStorage.setItem('userId', id);
};

/** This method used for add getting the active class in sidebar menu */
export const navigator = (activeMenuName, navigator) => {
  return activeMenuName && navigator && activeMenuName === navigator.name
    ? 'active'
    : '';
};
export const getActiveClassNew = (navigator) => {
  let location = useLocation();
  return navigator.path == location.pathname ? 'active' : '';
};

/** This method used for check the array length in listing components*/
export const checkArrayLength = (list) => {
  if (list && list.length > 0) {
    return true;
  }
  return false;
};

export const addSortingClassInHeader = (sort) => {
  return sort ? 'sorting' : '';
};

/** Get The profile image*/
export const getTheProfileImage = (profile, key) => {
  if (profile?.attributes && profile.attributes[key]) {
    let image = profile.attributes[key];
    return image;
  } else {
    return '';
  }
};

export const getTheGenresTypeImage = (url) => {
  if (url) {
    return getBaseURLForImage() + url;
  }
};

export const handleLoaderImg = () => {
  return 'Loader';
};

export const getImageName = (path) => {
  if (path === null) {
    return null;
  } else {
    let array = path.split('/');
    let index = array.length - 1;
    return array.length > 0 && array[index].split('%').join(' ');
  }
};
