const constants = {
  profileImageUrlMaxLength: 20,
  loginPage: {
    title: 'Account Login',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    rememberMePlaceholder: 'Remember Me',
    buttons: {
      login: 'Login',
    },
  },
  dashboard: {
    companiesTitle: 'List of Companies',
    newEntityTitle: 'List of New Companies',
    suspendedEntityTitle: 'List of Suspended Companies',
    searchPlaceholder: 'Find by Name',
    tabTitles: ['Companies', 'New Companies', 'Suspended Companies'],
    cardLabels: ['Companies', 'URLs', 'Data Points', 'New Companies'],
    newEntityHeaders: [
      { label: 'No.' },
      { label: 'Company' },
      { label: 'Action' },
    ],
    pageText: 'Company List',
    buttons: {
      update: 'Update',
    },
    dropdownOptions: [
      { value: 'All', key: 'all' },
      { value: 'Startup', key: 'startup' },
      { value: 'Investor', key: 'investor' },
      { value: 'Accelerator', key: 'accelerator' },
    ],
  },
  addNewUser: {
    title: 'Add User Details',
    firstNamePlaceholder: 'First Name',
    lastNamePlaceholder: 'Last Name',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    adminPlaceholder: 'Admin',
    confirmPasswordPlaceholder: 'Confirm Password',
    buttons: {
      addUser: 'Save Details',
    },
  },
  editUser: {
    title: 'Edit User Details',
    firstNamePlaceholder: 'First Name',
    lastNamePlaceholder: 'Last Name',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    adminPlaceholder: 'Admin',
    confirmPasswordPlaceholder: 'Confirm Password',
    buttons: {
      editUser: 'Save Details',
    },
  },
  addCreatorPlaceholder: {
    namePlaceholder: ' Name',
    bioPlaceholder: ' Bio here...',
    contactNoPlaceholder: ' Contact Number',
    locationPlaceholder: ' Location',
    occupationPlaceholder: ' Occupation',
    websitePlaceholder: ' Website ',
    experiencePlaceholder: ' your experience',
    passionPlaceholder: ' your passion',
    buttonPlaceholder: ' Save Details',
  },
  addProfilePlaceholder: {
    namePlaceholder: ' Name',
    contactNoPlaceholder: ' Contact Number',
    locationPlaceholder: ' Location',
    buttonPlaceholder: ' Add Details',
  },
  addGenrePlaceholder: {
    namePlaceholder: ' Title',
    descriptionPlaceholder: ' Description',
    tagPlaceholder: ' Tag',
    backgroundProfileImage: 'Cover Photo',
    typePlaceholder: 'Type',
    buttonPlaceholder: ' Save Details',
    resetPlaceHolder: 'Reset',
  },
  addOrganiserPlaceholder: {
    namePlaceholder: 'Name',
    aboutPlaceholder: 'About',
    logoImagePlaceholder: 'Logo Photo',
    emailPlaceholder: 'Email',
    phonePlaceholder: 'Phone',
    passwordPlaceholder: 'Password',
    confirmPasswordPlaceholder: 'Confirm Password',
    buttonPlaceholder: 'Save Details',
    resetPlaceHolder: 'Reset',
  },
  addEventPlaceholder: {
    bannerImagePlaceHolder: "Ticket Banner",
    listImagePlaceHolder: "List Image",
    sliderImagePlaceHolder: "Slider Image",
    locationUrlPlaceHolder: "Venue location URL",
    artistPlaceHolder: "Artist",
    eventLanguagePlaceHolder: "Event Language",
    countryPlaceHolder: "Event Country",
    ageRestrictionPlaceHolder: "Age Restriction",
    resetPlaceHolder: 'Reset',
    buttonPlaceholder: "Submit",
    titlePlaceholder: 'Event Title',
    bodyPlaceholder: 'Event body',
    pricePlaceholder: 'Price',
    currencyPlaceholder: 'Currency',
    venueNamePlaceholder: 'Venue City',
    venueAddressPlaceholder: 'Venue Address',
    latPlaceholder: 'Location Latitude',
    longPlaceholder: 'Location Longitute',
    startTimePlaceHolder: 'Event Start Time',
    endTimePlaceHolder: 'Event End Time',
    personCapacityPlaceHolder: 'Person Capacity',
    soldTicketPlaceHolder: 'Sold Tickets',
    coverImgPlaceHolder: 'Cover Image',
    eventTypePlaceHolder: 'Event Type',
    eventSaleStartTimePlaceHolder: 'Event Sale Start Time',
    organiserPlaceHolder: 'Organiser',
    typeOfTicketPlaceHolder: 'Type of Ticket',
  },
  editGenrePlaceholder: {
    namePlaceholder: ' Title',
    descriptionPlaceholder: ' Description',
    tagPlaceholder: ' Tag',
    backgroundProfileImage: 'Cover Photo',
    typePlaceholder: 'Type',
    buttonPlaceholder: ' Save Details',
  },
  addAficionadoPlaceholder: {
    namePlaceholder: ' Name',
    bioPlaceholder: ' Bio here...',
    contactNoPlaceholder: ' Contact Number',
    locationPlaceholder: ' Location',
    occupationPlaceholder: ' Occupation',
    websitePlaceholder: ' Website ',
    experiencePlaceholder: ' your experience',
    passionPlaceholder: ' your passion',
    buttonPlaceholder: ' Save Details',
  },
  profile: {
    title: 'Edit Profile',
    firstNamePlaceholder: 'First Name',
    lastNamePlaceholder: 'Last Name',
    bioPlaceholder: 'Bio',
    emailPlaceholder: 'Email',
    adminPlaceholder: 'Admin',
    experiencePlaceholder: 'Experience',
    locationPlaceHolder: 'Location',
    occupationPlaceHolder: 'occupation',
    websitePlaceHolder: 'Website',
    profilePlaceHolder: 'Profile Image',
    backGroundPlaceHolder: 'Cover Image',
    changePasswordPlaceholder: 'Change Password?',
    buttons: {
      update: 'Update',
      label: 'AddProfile',
      resetPlaceHolder: 'Reset',
    },
    changePasswordModal: {
      title: 'Change Password',
      oldPasswordPlaceholder: 'Old Password',
      newPasswordPlaceholder: 'New Password',
      confirmPasswordPlaceholder: 'Confirm Password',
      footerButtons: {
        cancel: 'Cancel',
        change: 'Change',
      },
    },
  },
  manageUsers: {
    title: 'List of Users',
    pageText: 'User List',
    // searchText: 'Find by Name or Email',
    searchPlaceholder: 'Find by Name or Email',
    adminPlaceholder: 'Admin',
    userPlaceholder: 'User',
    headers: [
      { label: 'Name', id: 'profile_name', sort: true },
      { label: 'Email', id: 'email', sort: true },
      { label: 'Occupation', id: 'occupation' },
      { label: 'Created Date', id: 'created_at' },
      { label: 'Location', id: 'location' },
      { label: 'Status', id: 'status' },
      { label: 'Role', id: 'role' },
    ],
    filterStatusPlaceholder: 'Filter By Status',
    dropdownList: [
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' },
    ],
    filterRolePlaceholder: 'Filter By Role',
    userdropdownList: [
      { name: 'User', value: 'users' },
      { name: 'Creators', value: 'creators' },
      { name: 'Aficionado', value: 'aficionados' },
    ],
    buttons: {
      add: 'Creator',
    },
  },
  mangeEvent: {
    title: 'List of Events',
    pageText: 'Event List',
    adminPlaceholder: 'Admin',
    userPlaceholder: 'User',
    headers: [
      { label: 'Performer', id: 'performer' },
      { label: 'Event Titile', id: 'title' },
      { label: 'Event amount', id: 'price', sort: true },
      { label: 'Venue', id: 'venue_name' },
      { label: 'Date & Time', id: 'start_time' },
    ],
    buttons: {
      add: 'Event',
    },
    dropdownList: [
      { name: 'Upcoming Events', value: 'latest' },
      { name: 'Past Events', value: 'past_events' },
    ],
    confirmationModal: {
      title: 'Are you sure you want to delete this event?',
      message: 'All the data associated with this event will be deleted.',
    },
  },
  mangeGenre: {
    title: 'List of Genres',
    pageText: 'Genres List',
    searchPlaceholder: 'Find by Type',
    adminPlaceholder: 'Admin',
    userPlaceholder: 'User',
    headers: [
      { label: 'Type', id: 'name', sort: true },
      { label: 'Cover Image', id: 'img_path' },
    ],
    buttons: {
      add: 'Genre',
    },
    confirmationModal: {
      title: 'Are you sure you want to delete this genre?',
      message: 'All the data associated with this genre will be deleted.',
    },
  },
  mangeOrganiser: {
    title: 'List of Organisers',
    pageText: 'Organiser List',
    searchPlaceholder: 'Find by Type',
    headers: [
      { label: 'Name', id: 'name', sort: true },
      { label: 'Email', id: 'email' },
      { label: 'Phone', id: 'phone' },
      { label: 'Status', id: 'status' },
    ],
    buttons: {
      add: 'Organiser',
    },
    confirmationModal: {
      title: 'Are you sure you want to delete this organiser?',
      message: 'All the data associated with this organiser will be deleted.',
    },
  },
  itemInPage: 20,
  resetPlaceHolder: 'Reset',
  backPlaceHolder: '< Back',
  manageAficionado: {
    title: 'List of Aficionados',
    pageText: 'User List',
    searchPlaceholder: 'Find by Name or Email',
    adminPlaceholder: 'Admin',
    userPlaceholder: 'User',
    headers: [
      { label: 'Name', id: 'profile_name', sort: true },
      { label: 'Email', id: 'email', sort: true },
      { label: 'Occupation', id: 'occupation' },
      { label: 'Created Date', id: 'created_at' },
      { label: 'Location', id: 'location' },
      { label: 'Status', id: 'status' },
    ],
    filterStatusPlaceholder: 'Filter By Status',
    dropdownList: [
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' },
    ],
    buttons: {
      add: 'Aficionado',
    },
  },
  companyTable: {
    pageText: 'Company List',
    buttons: {
      update: 'Update',
    },
    headers: [
      { label: 'No.' },
      { label: 'Company', sort: true },
      { label: 'Sector' },
      { label: 'Funding Stage' },
      { label: 'UEN' },
      { label: 'Updated On' },
      { label: 'View' },
    ],
  },
  confirmationModal: {
    footerButtons: {
      cancel: 'Cancel',
      delete: 'Delete',
    },
  },
  pagination: {
    pagePlaceholder: 'Page',
    ofPlaceholder: 'of',
  },
  header: {
    profilePlaceholder: 'Manage Profile',
    logOutPlaceholder: 'Log Out',
  },
  sidebar: {
    dashboardPlaceholder: 'Dashboard',
    addUserPlaceholder: 'Add User',
    manageUsersPlaceholder: 'Manage Users',
    creatorPlaceHolder: 'Creator Management',
    AficionadoPlaceHolder: 'Aficionado Management',
    InvitationPlaceholder: 'Invitation Management',
  },
  tooltips: {
    exampleTooltip: 'Example Tooltip',
  },
  sendInvitationPlaceHolder: {
    emailPlaceHolder: 'Email',
    headers: [
      { label: 'Email', id: 'email', sort: true },
      { label: 'Role', id: 'email' },
      { label: 'Status', id: 'accepted' },
    ],
    searchPlaceHolder: 'Find by Email',
    rolePlaceHolder: 'Choose Role',
    dropdownList: [
      { name: 'Accepted', value: 'accepted' },
      { name: 'Pending', value: 'not_accepted' },
    ],
  },
  filterStatusPlaceholder: 'Filter By Status',
  forgotPassword: {
    title: 'Forgot Password',
    emailPlaceholder: 'Email',
    buttons: {
      login: 'Forgot Password',
    },
  },
  creatorModal: {
    emailPlaceholder: 'Email',
    namePlaceholder: 'Name',
    creator_title: 'Creator Details',
    occupationPlaceHolder: 'Occupation',
    bioPlaceHolder: 'Bio',
    createdDatePlaceHolder: 'Created Date',
    locationPlaceHolder: 'Location',
    statusPlaceHolder: 'Status',
  },
  genreModal: {
    typePlaceholder: 'Type',
    imagePlaceholder: 'Image',
    genres_title: 'Genre Details',
  },
  aficionadoModal: {
    emailPlaceholder: 'Email',
    namePlaceholder: 'Name',
    aficionado_title: 'Aficionado Details',
  },
  acceptInvitation: {
    title: 'Set your password',
    rememberMePlaceholder: 'Remember Me',
    passwordPlaceholder: 'Password',
    confirmationPasswordPlaceholder: 'Confirm Password',
    buttons: {
      login: 'Set my password',
    },
    popupLoginButton: 'Login',
  },
  manageEvent: {
    confirmationModal: {
      title: 'Are you sure you want to delete this event?',
      message: 'All the data associated with this event will be deleted.',
    },
  },

  resetPassword: {
    title: 'Reset Password',
    rememberMePlaceholder: 'Remember Me',
    passwordPlaceholder: 'Password',
    confirmationPasswordPlaceholder: 'Confirm Password',
    buttons: {
      login: 'Reset Password',
    },
    popupLoginButton: 'Login',
    resetPasswordConfirmationModal: {
      modalTitle: "",
      message: "Your password has been updated. You can login from your mobile app."
    },
    acceptInvitationModal: {
      modalTitle: "",
      message: "You have successfully registered on pargo and now you can login from mobile app"
    }
  },
  multipartFormData: {
    contentType: { 'Content-Type': 'multipart/form-data' },
  },
  tableHeader: {
    emptyHeader: 'No data in header',
  },
  sideMenuMapper: [
    {
      name: 'creator',
      label: 'User Management',
      icon: 'far fa-user',
      flag: false,
      id: 0,
      path: '/creator',
    },
    {
      name: 'genre',
      label: 'Genre Management',
      icon: 'fas fa-music',
      flag: false,
      id: 2,
      path: '/genre',
    },
    {
      name: 'event',
      label: 'Event Management',
      icon: 'fas fa-calendar',
      flag: false,
      id: 3,
      path: '/events',
    },
    {
      name: 'organiser',
      label: 'Organisers',
      icon: 'fas fa-clipboard-list',
      flag: false,
      id: 3,
      path: '/organisers',
    },
    {
      name: 'sendInvitation',
      label: 'Send Invitation',
      icon: 'fas fa-envelope-open-text',
      flag: false,
      id: 4,
      path: '/sendInvitation',
    },
    // {
    //   name: 'profile',
    //   label: 'Manage Profile',
    //   icon: 'fas fa-envelope-open-text',
    //   flag: false,
    //   id: 5,
    //   path: '/profile',
    // },
  ],
  events: {
    logout: new Event('logout'),
  },
};
export default constants;
