import React from 'react';
import { Form } from 'react-bootstrap';
// import { Checkbox } from "pretty-checkbox-react";
// import "pretty-checkbox/src/pretty-checkbox.scss";

const CustomRadioButton = ({
  controlId,
  label,
  error,
  showError,
  inputRef,
  name,
  disabled,
  value,
  isControlled,
  onChange,
  dataFor,
  registeredEvents,
  id,
  isRequired,
  hintText,
}) => {
  return (
    <Form.Group controlId={controlId}>
      {/* {isRequired && <span className="required"> *</span>} */}
      {/* {label && (
        <Form.Label>
          {label}
          {isRequired && <span className="required"> *</span>}
          {hintText && <span className="input-example">{hintText}</span>}
        </Form.Label>
      )} */}
      <Form.Check
        inline
        label={label}
        name={name}
        data-tip
        data-for={dataFor}
        type={'radio'}
        id={id}
        onChange={onChange}
        setState={onChange}
        disabled={disabled}
        value={value}
        {...registeredEvents}
      />

      {showError && error && (
        <Form.Text className="error-text">{error}</Form.Text>
      )}
    </Form.Group>
  );
};

export default CustomRadioButton;
