export const SET_SIDEBAR_COLLAPSE = 'SET_SIDEBAR_COLLAPSE';
export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY';
export const SET_SIDEBAR_ACTIVE_MENU_NAME = 'SET_SIDEBAR_ACTIVE_MENU_NAME';

export const setSidebarCollapse = (isCollapsed) => (dispatch, getState) => {
  dispatch({
    type: SET_SIDEBAR_COLLAPSE,
    payload: {
      isCollapsed: isCollapsed,
      isVisible: getState().sidebar.isVisible,
      activeMenuName: getState().sidebar.activeMenuName,
    },
  });
};

export const setSidebarVisibility = (isVisible) => (dispatch, getState) => {
  dispatch({
    type: SET_SIDEBAR_VISIBILITY,
    payload: {
      isCollapsed: getState().sidebar.isCollapsed,
      activeMenuName: getState().sidebar.activeMenuName,
      isVisible: isVisible,
    },
  });
};

export const setActiveMenuName = (activeMenuName) => (dispatch, getState) => {
  dispatch({
    type: SET_SIDEBAR_ACTIVE_MENU_NAME,
    payload: {
      activeMenuName: activeMenuName,
      isCollapsed: getState().sidebar.isCollapsed,
      isVisible: getState().sidebar.isVisible,
    },
  });
};
