import { decrementLoaderCount, incrementLoaderCount } from './loader';
import { getCreatorList } from '../apis/creatorManagement';

export const GET_CREATOR_LIST = 'GET_CREATOR_LIST';

/** This method used for getting the listing data into the API*/
export const getCreatorListData = (body, role) => (dispatch) => {
  dispatch(incrementLoaderCount(true));
  return getCreatorList(body, role)
    .then((res) => {
      if (res?.data?.status) {
        const creatorList = {
          items: (res?.data && res?.data?.data?.users) || [],
          totalItemCount: (res?.data && res?.data?.records) || 0,
          totalPages: (res?.data && res?.data?.total_pages) || 0,
        };
        dispatch(decrementLoaderCount(false));
        dispatch(setCreaterList(creatorList));
      }
      return res?.data;
    })
    .catch(() => dispatch(decrementLoaderCount(false)));
};

/** This method used for set the listing data into the API*/
export const setCreaterList = (data) => ({
  type: GET_CREATOR_LIST,
  payload: data,
});
