import react from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import Button from './button';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/common/datepicker.scss"

const DateTimePickerComponent = (props) => {

    const {
        inputRef,
        registeredEvents,
        name,
        controlId,
        label,
        error,
        showError,
        onChange,
        isRequired,
        value,
        hintText,
        minDate,
        minTime,
    } = props;
    return (

        <Form.Group
            controlId={controlId}>
            {label && (
                <Form.Label>
                    {label}
                    {isRequired && <span className="required"> *</span>}
                    {hintText && <span className="input-example">{hintText}</span>}
                </Form.Label>
            )}
            <InputGroup>
                <DatePicker
                    {...(registeredEvents || {})}
                    ref={inputRef}
                    id={name}
                    name={name}
                    minDate={minDate ? minDate : new Date()}
                    minTime={minTime}
                    timeIntervals={15}
                    dateFormat={"dd/MM/yyyy hh:mm aa"}
                    showTimeSelect
                    className={"form-control"}
                    value={value}
                    selected={value}
                    onChange={(value) => {
                        console.log("value 1", value)
                        onChange(value)
                    }}
                    placeholderText={label ? label : "Select Date"}
                />
            </InputGroup>
            {showError && error && (
                <Form.Text className="error-text">{error}</Form.Text>
            )}
        </Form.Group>


    )
}

export default DateTimePickerComponent;