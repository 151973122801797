import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Img } from 'react-image';

const Image = ({ src, style, className, key }) => {
  return (
    <div>
      <Img
        // key={key}
        src={src}
        style={style}
        className={className}
        unloader={
          <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
        }
        loader={
          <Spinner as="span" animation="border" size="sm" aria-hidden="true" />
        }
      />
    </div>
  );
};

export default Image;
