import { decrementLoaderCount, incrementLoaderCount } from './loader';
import { getInvitationList } from '../apis/invitationManagement';
export const GET_INVITATION_LIST = 'GET_INVITATION_LIST';

/** This method used for getting the listing data into the API*/
export const getInvitationListData = (body) => (dispatch) => {
  dispatch(incrementLoaderCount(true));
  return getInvitationList(body)
    .then((res) => {
      if (res?.data?.status) {
        const invitationList = {
          items: (res?.data && res?.data?.data?.users) || [],
          totalItemCount: (res?.data && res?.data?.records) || 0,
          totalPages: (res?.data && res?.data?.total_pages) || 0,
        };
        dispatch(decrementLoaderCount(false));
        dispatch(setInvitationList(invitationList));
      }

      return res?.data;
    })
    .catch(() => dispatch(decrementLoaderCount(false)));
};

/** This method used for set the listing data into the API*/
export const setInvitationList = (data) => ({
  type: GET_INVITATION_LIST,
  payload: data,
});
