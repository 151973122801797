import { decrementLoaderCount, incrementLoaderCount } from './loader';
import { getEventList } from '../apis/eventManagement';

export const GET_EVENT_LIST = 'GET_EVENT_LIST';
export const GET_EVENT_DATA = 'GET_EVENT_DATA';

export const getEventListData = (body) => (dispatch) => {
  dispatch(incrementLoaderCount(true));
  return getEventList(body)
    .then((res) => {
      if (res?.data?.status) {
        const eventList = {
          items: (res?.data && res?.data?.data?.events) || [],
          totalItemCount: (res?.data && res?.data?.records) || 0,
          totalPages: (res?.data && res?.data?.total_pages) || 0,
        };
        dispatch(setEventList(eventList));
      }
      dispatch(decrementLoaderCount(false));

      return res?.data?.events;
    })
    .catch(() => dispatch(decrementLoaderCount(false)));
};

export const setEventList = (data) => ({
  type: GET_EVENT_LIST,
  payload: data,
});
