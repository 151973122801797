import { SET_AUTOSUGGESTION_DATA } from '../actions/search';

export const INITIAL_STATE = {};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTOSUGGESTION_DATA: {
      return Object.assign({}, state, { [action.key]: action.payload });
    }
    default: {
      return state;
    }
  }
};

export default searchReducer;
