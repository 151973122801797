import { GET_CREATOR_LIST } from '../actions/creatorManagement';

export const INITIAL_STATE = {
  creatorList: {},
};

const creatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CREATOR_LIST: {
      return {
        creatorList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default creatorReducer;
