import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { Input, Button, SelectDropdown } from '../../../components';
import {
  useStateCallback,
  showToast,
  convertOrganiserFormData,
  formatDateAndTime,
  convertEventFormData,
  getOrganiserDropDownListData,
  formatDate,
  getAgeRestrictionDrpData,
  AgeDrpList,
  getCurrentTimeZone,
  getTimeZone,
} from '../../../utility/common';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from '../../../schema/event';
import { constants, messages } from '../../../constants';
import '../../../styles/event.scss';
import DateTimePickerComponent from '../../../components/common/DateTimePicker';
import { getOrganiserListData } from '../../../actions/organiserManagement';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from '../../../apis/eventManagement';

const CreateEvent = ({ history }) => {
  const dispatch = useDispatch();
  const organiserDetails = useSelector((state) => state?.organiserDetails);
  const profile = useSelector((state) => state.profile);
  const { organiserList } = organiserDetails || {};

  const [state, setState] = useStateCallback({
    isLoading: false,
    event_list_img_path: '',
    event_slidder_img_path: '',
    cover_img_path: '',
    ticket_banner_img_path: '',
    startDate: '',
    endDate: '',
    saleTicketTime: '',
  });

  const { isLoading } = state;
  const userType = localStorage.getItem('type');
  let inputRef = useRef(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      country: '',
      language: '',
      locationUrl: '',
      artist: '',
      age: '',
      event_list_img_path_name: '',
      event_slidder_img_path_name: '',
      cover_img_path_name: '',
      ticket_banner_img_path_name: '',
      title: '',
      body: '',
      price: '',
      currency: 'USD',
      venue: '',
      venueAddress: '',
      lat: '',
      long: '',
      startTime: '',
      endTime: '',
      personCapacity: '',
      soldTickets: '',
      event_list_img_path: '',
      event_slidder_img_path: '',
      cover_img_path: '',
      ticket_banner_img_path: '',
      eventType: '',
      ticketSaleStartTime: '',
      organiser: '',
      typeOfTicket: '',
      offset_time: '',
    },
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    document.title = 'ParGo | Events';
  }, []);

  useEffect(() => {
    if (userType != 'organiser') dispatch(getOrganiserListData());
    else setValue('organiser', profile?.id);
  }, []);

  const {
    buttonPlaceholder,
    resetPlaceHolder,
    bodyPlaceholder,
    coverImgPlaceHolder,
    currencyPlaceholder,
    endTimePlaceHolder,
    eventSaleStartTimePlaceHolder,
    eventTypePlaceHolder,
    latPlaceholder,
    longPlaceholder,
    organiserPlaceHolder,
    personCapacityPlaceHolder,
    pricePlaceholder,
    soldTicketPlaceHolder,
    startTimePlaceHolder,
    titlePlaceholder,
    typeOfTicketPlaceHolder,
    venueAddressPlaceholder,
    venueNamePlaceholder,
    ageRestrictionPlaceHolder,
    eventLanguagePlaceHolder,
    countryPlaceHolder,
    locationUrlPlaceHolder,
    artistPlaceHolder,
    listImagePlaceHolder,
    sliderImagePlaceHolder,
    bannerImagePlaceHolder,
  } = constants.addEventPlaceholder;
  const { fileUploadedValidation } = messages;

  const event_list_img_path_name = watch('event_list_img_path_name');
  const event_slidder_img_path_name = watch('event_slidder_img_path_name');
  const cover_img_path_name = watch('cover_img_path_name');
  const ticket_banner_img_path_name = watch('ticket_banner_img_path_name');

  const onSubmit = (data) => {
    var body = convertEventFormData(data);
    setState(
      {
        ...state,
        isLoading: true,
      },
      () => {
        createEvent(body)
          .then((res) => {
            if (res?.data?.status) {
              showToast(res?.data?.message);
              history.push('/events');
            } else {
              showToast(res?.data?.errors);
            }
            setState({
              ...state,
              isLoading: false,
            });
          })
          .catch(() =>
            setState({
              ...state,
              isLoading: false,
            }),
          );
      },
    );
  };
  return (
    <Container>
      <Row className="edit-user-form">
        <Col lg={12} xl={12}>
          {/* <Button label={backPlaceHolder}  /> */}
          <Card>
            <Card.Body className="pad-1">
              <a className="back-button" onClick={() => history.goBack()}>
                <i className="fas fa-angle-left"></i> Back
              </a>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={6}>
                    <Input
                      tabIndex={1}
                      controlId="title"
                      error={errors?.title?.message}
                      showError={true}
                      registeredEvents={register('title')}
                      isRequired={true}
                      placeholder={titlePlaceholder}
                      label={titlePlaceholder}
                      autoFocus={true}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type={'textarea'}
                      controlId="body"
                      error={errors?.body?.message}
                      showError={true}
                      registeredEvents={register('body')}
                      isRequired={true}
                      placeholder={bodyPlaceholder}
                      label={bodyPlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                {/* Language */}
                <Row>
                  <Col md={6}>
                    <Input
                      controlId="venue"
                      error={errors?.venue?.message}
                      showError={true}
                      registeredEvents={register('venue')}
                      isRequired={true}
                      placeholder={venueNamePlaceholder}
                      label={venueNamePlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      controlId="country"
                      error={errors?.country?.message}
                      showError={true}
                      registeredEvents={register('country')}
                      isRequired={true}
                      placeholder={countryPlaceHolder}
                      label={countryPlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      type={'textarea'}
                      controlId="venueAddress"
                      error={errors?.venueAddress?.message}
                      showError={true}
                      registeredEvents={register('venueAddress')}
                      isRequired={true}
                      placeholder={venueAddressPlaceholder}
                      label={venueAddressPlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      controlId="language"
                      error={errors?.language?.message}
                      showError={true}
                      registeredEvents={register('language')}
                      isRequired={true}
                      placeholder={eventLanguagePlaceHolder}
                      label={eventLanguagePlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      controlId="price"
                      error={errors?.price?.message}
                      showError={true}
                      registeredEvents={register('price')}
                      isRequired={true}
                      placeholder={pricePlaceholder}
                      label={pricePlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      controlId="currency"
                      error={errors?.currency?.message}
                      showError={true}
                      // disabled={true}
                      readOnly={true}
                      registeredEvents={register('currency', {
                        defaultValue: 'USD',
                      })}
                      isRequired={true}
                      placeholder={currencyPlaceholder}
                      label={currencyPlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      controlId="lat"
                      error={errors?.lat?.message}
                      showError={true}
                      registeredEvents={register('lat')}
                      isRequired={false}
                      placeholder={latPlaceholder}
                      label={latPlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      controlId="long"
                      error={errors?.long?.message}
                      showError={true}
                      registeredEvents={register('long')}
                      isRequired={false}
                      placeholder={longPlaceholder}
                      label={longPlaceholder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field }) => (
                        <DateTimePickerComponent
                          inputRef={inputRef}
                          registeredEvents={register('startTime')}
                          name={'startTime'}
                          value={state.startDate}
                          controlId="startTime"
                          error={errors?.startTime?.message}
                          onChange={(value) => {
                            setValue(
                              'startTime',
                              value ? formatDateAndTime(value) : '',
                              { shouldValidate: true },
                            );

                            setState({
                              ...state,
                              startDate: value,
                            });
                          }}
                          showError={true}
                          isRequired={true}
                          placeholder={startTimePlaceHolder}
                          label={startTimePlaceHolder}
                          autoFocus={false}
                        />
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field }) => (
                        <DateTimePickerComponent
                          minDate={state.startDate}
                          inputRef={inputRef}
                          registeredEvents={register('endTime')}
                          name={'endTime'}
                          value={state.endDate}
                          controlId="endTime"
                          error={errors?.endTime?.message}
                          onChange={(value) => {
                            setValue(
                              'endTime',
                              value ? formatDateAndTime(value) : '',
                              { shouldValidate: true },
                            );

                            setState({
                              ...state,
                              endDate: value,
                            });
                          }}
                          showError={true}
                          isRequired={true}
                          placeholder={endTimePlaceHolder}
                          label={endTimePlaceHolder}
                          autoFocus={false}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Controller
                      name="ticketSaleStartTime"
                      control={control}
                      render={({ field }) => (
                        <DateTimePickerComponent
                          inputRef={inputRef}
                          registeredEvents={register('ticketSaleStartTime')}
                          name={'ticketSaleStartTime'}
                          value={state.saleTicketTime}
                          controlId="ticketSaleStartTime"
                          error={errors?.ticketSaleStartTime?.message}
                          onChange={(value) => {
                            setValue(
                              'ticketSaleStartTime',
                              value ? formatDateAndTime(value) : '',
                              { shouldValidate: true },
                            );

                            setState({
                              ...state,
                              saleTicketTime: value,
                            });
                          }}
                          showError={true}
                          isRequired={false}
                          placeholder={eventSaleStartTimePlaceHolder}
                          label={eventSaleStartTimePlaceHolder}
                          autoFocus={false}
                        />
                      )}
                    />
                  </Col>
                  <Col md={6}>
                    <Controller
                      className={'form-control'}
                      name="offset_time"
                      control={control}
                      render={({ field }) => (
                        <SelectDropdown
                          {...field}
                          isRequired={true}
                          inputRef={inputRef}
                          onChange={(filtersData) => {
                            setValue(
                              'offset_time',
                              filtersData && filtersData.value
                                ? filtersData.value
                                : '',
                              { shouldValidate: true },
                            );
                            //onSearchFilter(filtersData?.value || '');
                          }}
                          error={errors?.offset_time?.message}
                          labelText={'offset time'}
                          placeholder={'Select Offset Time'}
                          options={getTimeZone}
                          closeMenuOnSelect
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      controlId="personCapacity"
                      error={errors?.personCapacity?.message}
                      showError={true}
                      registeredEvents={register('personCapacity')}
                      isRequired={true}
                      placeholder={personCapacityPlaceHolder}
                      label={personCapacityPlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      disabled={true}
                      controlId="soldTickets"
                      error={errors?.soldTickets?.message}
                      showError={true}
                      registeredEvents={register('soldTickets')}
                      isRequired={true}
                      placeholder={soldTicketPlaceHolder}
                      label={soldTicketPlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      controlId="eventType"
                      error={errors?.eventType?.message}
                      showError={true}
                      registeredEvents={register('eventType')}
                      isRequired={true}
                      placeholder={eventTypePlaceHolder}
                      label={eventTypePlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Controller
                      className={'form-control'}
                      name="age"
                      control={control}
                      render={({ field }) => (
                        <SelectDropdown
                          {...field}
                          isRequired={true}
                          inputRef={inputRef}
                          onChange={(filtersData) => {
                            setValue('age', filtersData?.value || '', {
                              shouldValidate: true,
                            });
                            //onSearchFilter(filtersData?.value || '');
                          }}
                          error={errors?.age?.message}
                          labelText={ageRestrictionPlaceHolder}
                          placeholder={ageRestrictionPlaceHolder}
                          options={AgeDrpList}
                          closeMenuOnSelect
                        />
                      )}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Input
                      controlId="typeOfTicket"
                      error={errors?.typeOfTicket?.message}
                      showError={true}
                      registeredEvents={register('typeOfTicket')}
                      isRequired={true}
                      placeholder={typeOfTicketPlaceHolder}
                      label={typeOfTicketPlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      controlId="locationUrl"
                      error={errors?.locationUrl?.message}
                      showError={true}
                      registeredEvents={register('locationUrl')}
                      isRequired={false}
                      placeholder={locationUrlPlaceHolder}
                      label={locationUrlPlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Input
                      controlId="artist"
                      error={errors?.artist?.message}
                      showError={true}
                      registeredEvents={register('artist')}
                      isRequired={true}
                      placeholder={artistPlaceHolder}
                      label={artistPlaceHolder}
                      autoFocus={false}
                    />
                  </Col>
                  {userType != 'organiser' && (
                    <Col md={6}>
                      <Controller
                        className={'form-control'}
                        name="organiser"
                        control={control}
                        render={({ field }) => (
                          <SelectDropdown
                            {...field}
                            isRequired={true}
                            inputRef={inputRef}
                            onChange={(filtersData) => {
                              setValue('organiser', filtersData?.value || '', {
                                shouldValidate: true,
                              });
                              //onSearchFilter(filtersData?.value || '');
                            }}
                            error={errors?.organiser?.message}
                            labelText={organiserPlaceHolder}
                            placeholder={organiserPlaceHolder}
                            options={getOrganiserDropDownListData(
                              organiserList,
                              'name',
                              'id',
                            )}
                            closeMenuOnSelect
                          />
                        )}
                      />
                      {/* <Input
                      controlId="organiser"
                      error={errors?.organiser?.message}
                      showError={true}
                      registeredEvents={register('organiser')}
                      isRequired={true}
                      placeholder={organiserPlaceHolder}
                      label={organiserPlaceHolder}
                      autoFocus={false}
                    /> */}
                    </Col>
                  )}
                </Row>
                {/* new Images */}
                <Row>
                  <Col md={6}>
                    <div className="file-input-wrapper">
                      <Input
                        infoText={'Event List Image: 344Wx470H'}
                        controlId="event_list_img_path"
                        error={errors?.event_list_img_path?.message}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        showError={true}
                        registeredEvents={register('event_list_img_path')}
                        isControlled
                        isRequired={true}
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            var imageType = /image.*/;
                            if (e.target.files[0].type.match(imageType)) {
                              setValue(
                                'event_list_img_path_name',
                                e.target.files[0].name,
                                { shouldValidate: true },
                              );
                              setState({
                                ...state,
                                event_list_img_path: URL.createObjectURL(
                                  e.target.files[0],
                                ),
                              });
                              setValue(
                                'event_list_img_path',
                                e.target.files[0],
                                {
                                  shouldValidate: true,
                                },
                              );
                            } else {
                              showToast(fileUploadedValidation);
                            }
                          }
                        }}
                        placeholder={listImagePlaceHolder}
                        label={listImagePlaceHolder}
                        type={'file'}
                      />
                      <label
                        title={event_list_img_path_name}
                        className="labelText">
                        {event_list_img_path_name
                          ? event_list_img_path_name.length >=
                            constants.profileImageUrlMaxLength
                            ? event_list_img_path_name.slice(0, 20) + '...'
                            : event_list_img_path_name
                          : ''}
                      </label>
                    </div>
                    {state.event_list_img_path && (
                      <div className="image-preview">
                        {/* <span
                          onClick={() => {
                            setValue('event_list_img_path', '', {
                              shouldValidate: true,
                            });
                            setValue('event_list_img_path_name', '', {
                              shouldValidate: true,
                            });
                            setState({
                              ...state,
                              event_list_img_path_name: '',
                            });
                            setValue('img', '', {
                              shouldValidate: true,
                            });
                          }}>
                          ×
                        </span> */}
                        <img
                          style={{ width: '50%' }}
                          src={state.event_list_img_path}
                          alt={'ParGo'}></img>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="file-input-wrapper">
                      <Input
                        infoText={'Event Slidder Image: 240Wx360H'}
                        controlId="logo2"
                        error={errors?.event_slidder_img_path?.message}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        showError={true}
                        registeredEvents={register('event_slidder_img_path')}
                        isControlled
                        isRequired={true}
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            var imageType = /image.*/;
                            if (e.target.files[0].type.match(imageType)) {
                              setValue(
                                'event_slidder_img_path_name',
                                e.target.files[0].name,
                                {
                                  shouldValidate: true,
                                },
                              );
                              setState({
                                ...state,
                                event_slidder_img_path: URL.createObjectURL(
                                  e.target.files[0],
                                ),
                              });
                              setValue(
                                'event_slidder_img_path',
                                e.target.files[0],
                                {
                                  shouldValidate: true,
                                },
                              );
                            } else {
                              showToast(fileUploadedValidation);
                            }
                          }
                        }}
                        placeholder={sliderImagePlaceHolder}
                        label={sliderImagePlaceHolder}
                        type={'file'}
                      />
                      <label
                        title={event_slidder_img_path_name}
                        className="labelText">
                        {event_slidder_img_path_name
                          ? event_slidder_img_path_name.length >=
                            constants.profileImageUrlMaxLength
                            ? event_slidder_img_path_name.slice(0, 20) + '...'
                            : event_slidder_img_path_name
                          : ''}
                      </label>
                    </div>
                    {state.event_slidder_img_path && (
                      <div className="image-preview">
                        {/* <span
                          onClick={() => {
                            setValue('event_slidder_img_path', '', {
                              shouldValidate: true,
                            });
                            setValue('event_slidder_img_path_name', '', {
                              shouldValidate: true,
                            });
                            setState({
                              ...state,
                              event_slidder_img_path: '',
                            });
                            setValue('img', '', {
                              shouldValidate: true,
                            });
                          }}>
                          ×
                        </span> */}
                        <img
                          style={{ width: '50%' }}
                          src={state.event_slidder_img_path}
                          alt={'ParGo'}></img>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="file-input-wrapper">
                      <Input
                        infoText={'Event Banner Image: 375Wx375H'}
                        controlId="logo"
                        error={errors?.cover_img_path?.message}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        showError={true}
                        registeredEvents={register('cover_img_path')}
                        isControlled
                        isRequired={true}
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            var imageType = /image.*/;
                            if (e.target.files[0].type.match(imageType)) {
                              setValue(
                                'cover_img_path_name',
                                e.target.files[0].name,
                                {
                                  shouldValidate: true,
                                },
                              );
                              setState({
                                ...state,
                                cover_img_path: URL.createObjectURL(
                                  e.target.files[0],
                                ),
                              });
                              setValue('cover_img_path', e.target.files[0], {
                                shouldValidate: true,
                              });
                            } else {
                              showToast(fileUploadedValidation);
                            }
                          }
                        }}
                        placeholder={coverImgPlaceHolder}
                        label={coverImgPlaceHolder}
                        type={'file'}
                      />
                      <label title={cover_img_path_name} className="labelText">
                        {cover_img_path_name
                          ? cover_img_path_name.length >=
                            constants.profileImageUrlMaxLength
                            ? cover_img_path_name.slice(0, 20) + '...'
                            : cover_img_path_name
                          : ''}
                      </label>
                    </div>
                    {state.cover_img_path && (
                      <div className="image-preview">
                        {/* <span
                          onClick={() => {
                            setValue('cover_img_path_name', '', {
                              shouldValidate: true,
                            });
                            setValue('cover_img_path', '', {
                              shouldValidate: true,
                            });
                            setState({
                              ...state,
                              cover_img_path: '',
                            });
                            setValue('img', '', {
                              shouldValidate: true,
                            });
                          }}>
                          ×
                        </span> */}
                        <img
                          style={{ width: '50%' }}
                          src={state.cover_img_path}
                          alt={'ParGo'}></img>
                      </div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="file-input-wrapper">
                      <Input
                        infoText={'Ticket banner Image: 344Wx135H'}
                        controlId="logo3"
                        error={errors?.ticket_banner_img_path?.message}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        showError={true}
                        registeredEvents={register('ticket_banner_img_path')}
                        isControlled
                        isRequired={true}
                        accept="image/*"
                        onChange={(e) => {
                          if (e.target.files.length > 0) {
                            var imageType = /image.*/;
                            if (e.target.files[0].type.match(imageType)) {
                              setValue(
                                'ticket_banner_img_path_name',
                                e.target.files[0].name,
                                {
                                  shouldValidate: true,
                                },
                              );
                              setState({
                                ...state,
                                ticket_banner_img_path: URL.createObjectURL(
                                  e.target.files[0],
                                ),
                              });
                              setValue(
                                'ticket_banner_img_path',
                                e.target.files[0],
                                {
                                  shouldValidate: true,
                                },
                              );
                            } else {
                              showToast(fileUploadedValidation);
                            }
                          }
                        }}
                        placeholder={bannerImagePlaceHolder}
                        label={bannerImagePlaceHolder}
                        type={'file'}
                      />
                      <label
                        title={ticket_banner_img_path_name}
                        className="labelText">
                        {ticket_banner_img_path_name
                          ? ticket_banner_img_path_name.length >=
                            constants.profileImageUrlMaxLength
                            ? ticket_banner_img_path_name.slice(0, 20) + '...'
                            : ticket_banner_img_path_name
                          : ''}
                      </label>
                    </div>
                    {state.ticket_banner_img_path && (
                      <div className="image-preview">
                        {/* <span
                          onClick={() => {
                            setValue('ticket_banner_img_path_name', '', {
                              shouldValidate: true,
                            });
                            setValue('ticket_banner_img_path', '', {
                              shouldValidate: true,
                            });
                            setState({
                              ...state,
                              ticket_banner_img_path: '',
                            });
                            setValue('img', '', {
                              shouldValidate: true,
                            });
                          }}>
                          ×
                        </span> */}
                        <img
                          style={{ width: '50%' }}
                          src={state.ticket_banner_img_path}
                          alt={'ParGo'}></img>
                      </div>
                    )}
                  </Col>
                </Row>
                <Card.Footer>
                  <Row className="align-items-center">
                    <Col sm={2} xs={6}>
                      <Button
                        variant="success"
                        disabled={isLoading}
                        isLoading={isLoading}
                        label={buttonPlaceholder}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </Col>
                    {/* <Col sm={2} xs={6}>
                      <Button
                        variant="success"
                        label={resetPlaceHolder}
                        onClick={() => reset()}
                      />
                    </Col> */}
                  </Row>
                </Card.Footer>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateEvent;
