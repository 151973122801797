import * as Yup from 'yup';

const changePassword = Yup.object({
  current_password: Yup.string().required('This field is required.'),
  password: Yup.string()
    .required('This field is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    ),
  password_confirmation: Yup.string()
    .required('This field is required.')
    .oneOf([Yup.ref('password')], 'New password & confirm password must match.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
    ),
});

export default changePassword;
