import { get, post, del, put } from './client';
import { constants } from '../constants/';

const { contentType } = constants.multipartFormData;

export const getGenreList = (body) => {
  return get('/admin/genres', body);
};

export const addNewGenre = (body) => {
  return post('/admin/genres', body, contentType);
};

export const editGenre = (body, id) => {
  return put(`/admin/genres/${id}`, body, contentType);
};

export const getEditGenreDetails = (id) => {
  return get(`/admin/genres/${id}/edit`);
};

export const deleteGenre = (id) => {
  return del(`/admin/genres/${id}`);
};
