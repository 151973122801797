import {
  SET_SIDEBAR_COLLAPSE,
  SET_SIDEBAR_VISIBILITY,
  SET_SIDEBAR_ACTIVE_MENU_NAME,
} from '../actions/sidebar';

const sidebarReducer = (
  sidebar = { isCollapsed: false, isVisible: false, activeMenuName: 'creator' },
  action,
) => {
  switch (action.type) {
    case SET_SIDEBAR_COLLAPSE: {
      return action.payload;
    }
    case SET_SIDEBAR_VISIBILITY: {
      return action.payload;
    }
    case SET_SIDEBAR_ACTIVE_MENU_NAME: {
      return action.payload;
    }
    default: {
      return sidebar;
    }
  }
};

export default sidebarReducer;
