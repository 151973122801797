import {
  GET_ORGANISER_LIST,
  GET_ORGANISER_DATA,
} from '../actions/organiserManagement';

export const INITIAL_STATE = {
  organiserList: '',
};

const organiserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ORGANISER_LIST: {
      return {
        organiserList: action.payload,
      };
    }
    case GET_ORGANISER_DATA: {
      return {
        [action.key]: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default organiserReducer;
