import { GET_GENRE_LIST, GET_GENRES_DATA } from '../actions/genreManagement';

export const INITIAL_STATE = {
  genreList: '',
  editGenresData: '',
};

const genreReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GENRE_LIST: {
      return {
        genreList: action.payload,
      };
    }
    case GET_GENRES_DATA: {
      return {
        [action.key]: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default genreReducer;
