import { getOrganiserProfile, getProfile, updateProfile } from '../apis/profile';

const SET_PROFILE_DATA = 'SET_PROFILE_DATA';

/** This method used for set the profile Data*/
const setProfileData = (userId) => (dispatch) => {
  return getProfile(userId).then((res) => {
    if (res?.data?.status) {
      dispatch(profileDataAction(res?.data?.data?.user));
    }
    return res?.data;
  });
};

const setOrganiserProfileData = (userId) => (dispatch) => {
  return getOrganiserProfile(userId).then((res) => {
    if (res?.data?.status) {
      dispatch(profileDataAction(res?.data?.data?.organiser));
    }
    return res?.data;
  });
};

/** This method used for update the profile Data*/
const updateProfileData = (id, body) => (dispatch) => {
  return updateProfile(id, body).then((res) => {
    if (res?.data?.status) {
      dispatch(profileDataAction(res?.data?.data?.user));
    }
    return res?.data;
  });
};

/** This method used for set the update data into the profile*/
const profileDataAction = (profile) => ({
  type: SET_PROFILE_DATA,
  payload: profile,
});

export { setProfileData, updateProfileData, SET_PROFILE_DATA, setOrganiserProfileData };
