import { decrementLoaderCount, incrementLoaderCount } from './loader';
import { getAficionadoList } from '../apis/aficionadoManagement';
export const GET_AFICIONADO_LIST = 'GET_AFICIONADO_LIST';

/** This method used for getting the listing data into the API*/
export const getAficionadoListData = (body) => (dispatch) => {
  dispatch(incrementLoaderCount(true));
  return getAficionadoList(body)
    .then((res) => {
      if (res?.data?.status) {
        const aficionadoList = {
          items: (res?.data && res?.data?.data?.users) || [],
          totalItemCount: (res?.data && res?.data?.records) || 0,
          totalPages: (res?.data && res?.data?.total_pages) || 0,
        };
        dispatch(decrementLoaderCount(false));
        dispatch(setAficionadoList(aficionadoList));
      }

      return res?.data;
    })
    .catch(() => dispatch(decrementLoaderCount(false)));
};

/** This method used for set the listing data into the API*/
export const setAficionadoList = (data) => ({
  type: GET_AFICIONADO_LIST,
  payload: data,
});
