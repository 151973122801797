import React, { useEffect, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import AppErrorBoundary from './appErrorBoundary';
import { FullScreenLoader } from '../components';
import { getUserRole } from '../utility/common';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { setReduxLoaderCount } from '../actions/loader';
import { logout } from '../actions/login';
import UserRoute from './userRoute';
import AdminRoute from './adminRoute';
import CreateEvent from '../pages/eventManagement/createEvent';
import EditEvent from '../pages/eventManagement/editEvent';
const Login = lazy(() => import('../pages/login'));
const AddNewUser = lazy(() => import('../pages/addNewUser'));
const Profile = lazy(() => import('../pages/profile'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const ManageUsers = lazy(() => import('../pages/manageUsers'));
const EditUser = lazy(() => import('../pages/editUser'));
const CreatorListManagement = lazy(() =>
  import('../pages/creatorManagement/listCreator'),
);
const AddCreator = lazy(() => import('../pages/creatorManagement/addCreator'));
const AddAficionado = lazy(() =>
  import('../pages/aficionadoManagement/addAficionado'),
);
const ListAficionado = lazy(() =>
  import('../pages/aficionadoManagement/listAficionado'),
);
const SendInvitation = lazy(() => import('../pages/Invitation/index'));
const ForgotPassword = lazy(() => import('../pages/forgotPassword'));
const ResetPassword = lazy(() => import('../pages/resetPassword'));
const AcceptInvitation = lazy(() => import('../pages/acceptInvitation'));
const PageNotFound = lazy(() => import('../pages/errors/index'));
const AddGenre = lazy(() => import('../pages/genreManagement/addGenre'));
const GenreManagement = lazy(() =>
  import('../pages/genreManagement/listGenre'),
);
const OrganiserManagement = lazy(() =>
  import('../pages/organiserManagement/listOrganiser/index'),
);
const CreateOrganiser = lazy(() =>
  import('../pages/organiserManagement/createOrganiser/index'),
);
const EditOrganiser = lazy(() =>
  import('../pages/organiserManagement/editOrganiser/index'),
);
const EventManagement = lazy(() =>
  import('../pages/eventManagement/listEvent/index'),
);
const EditGenreManagement = lazy(() =>
  import('../pages/genreManagement/editGenre'),
);
const TermPrivacy = lazy(() => import('../pages/termPrivacy'));
const TermCondition = lazy(() => import('../pages/termPrivacy/termCondition'));
const PrivacyPolicy = lazy(() => import('../pages/termPrivacy/privacyPolicy'));
const AccountDeletion = lazy(() => import('../pages/accountdeletion'));
const Support = lazy(() => import('../pages/support'));

const ScrollToTop = (props) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);
  return props.children;
};

const Routes = () => {
  const { profile, token, loaderCount, state } = useSelector((state) => ({
    token: state.token,
    loaderCount: state.loaderCount,
    profile: state.profile,
    state: state
  }));

  let isAuthenticated = token;
  let userRole = getUserRole(profile);
  let userType = localStorage.getItem('type');
  const dispatch = useDispatch();
  useEffect(() => {
    loaderCount > 0 && dispatch(setReduxLoaderCount(0));
    document.addEventListener(
      'logout',
      () => {
        dispatch(logout(false));
      },
      false,
    );
  }, []);

  return (
    <AppErrorBoundary>
      <Router>
        <Suspense fallback={<FullScreenLoader />}>
          <ScrollToTop>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Redirect to={isAuthenticated ? userType == "organiser" ? "/events" : "creator" : '/login'} />
                )}
              />
              <Route
                exact
                path="/login"
                render={(props) =>
                  isAuthenticated ? (
                    <Redirect to={isAuthenticated ? userType == "organiser" ? "/events" : "creator" : '/login'} />
                  ) : (
                    <Login {...props} />
                  )
                }
              />
              <Route
                path="/forgot-password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                path={`/users/password/edit`}
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                path={`/users/invitation/accept`}
                render={(props) => <AcceptInvitation {...props} />}
              />
              <Route
                component={TermPrivacy}
                path="/term-privacy"
                exact
              />
              <Route
                component={TermCondition}
                path="/term-condition"
                exact
              />
              <Route
                component={PrivacyPolicy}
                path="/privacy-policy"
                exact
              />
              <Route
                component={AccountDeletion}
                path="/account-deletion"
                exact
              />
              <Route
                component={Support}
                path="/support"
                exact
              />

              <UserRoute
                isAuthenticated={isAuthenticated}
                component={Dashboard}
                path="/dashboard"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={Profile}
                path="/profile"
                loaderCount={loaderCount}
                exact
              />
              <AdminRoute
                isAuthenticated={isAuthenticated}
                component={AddNewUser}
                path="/add-new-user"
                loaderCount={loaderCount}
                exact
              />
              <AdminRoute
                isAuthenticated={isAuthenticated}
                component={EditUser}
                userRole={userRole}
                path="/edit-user/:id"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={OrganiserManagement}
                userRole={userRole}
                path="/organisers"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={CreateOrganiser}
                userRole={userRole}
                path="/organisers/create"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={EditOrganiser}
                userRole={userRole}
                path="/organisers/edit"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={ManageUsers}
                userRole={userRole}
                path="/manage-users"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={CreatorListManagement}
                userRole={userRole}
                path="/creator"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={AddCreator}
                userRole={userRole}
                path="/add-creator"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={AddAficionado}
                userRole={userRole}
                path="/add-aficionado"
                loaderCount={loaderCount}
                exact
              />

              <UserRoute
                isAuthenticated={isAuthenticated}
                component={ListAficionado}
                userRole={userRole}
                path="/aficionado"
                loaderCount={loaderCount}
                exact
              />

              <UserRoute
                isAuthenticated={isAuthenticated}
                component={AddGenre}
                userRole={userRole}
                path="/add-genre"
                loaderCount={loaderCount}
                exact
              />

              <UserRoute
                isAuthenticated={isAuthenticated}
                component={GenreManagement}
                userRole={userRole}
                path="/genre"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={EventManagement}
                userRole={userRole}
                path="/events"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={CreateEvent}
                userRole={userRole}
                path="/events/create"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={EditEvent}
                userRole={userRole}
                path="/events/edit"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={EditGenreManagement}
                userRole={userRole}
                path="/edit-genre"
                loaderCount={loaderCount}
                exact
              />
              <UserRoute
                isAuthenticated={isAuthenticated}
                component={SendInvitation}
                userRole={userRole}
                path="/sendInvitation"
                loaderCount={loaderCount}
                exact
              />

              <Route component={PageNotFound} />
            </Switch>
          </ScrollToTop>
        </Suspense>

        <Toaster
          position="bottom-right"
          toastOptions={{
            duration: 3000,
          }}
        />
      </Router>
    </AppErrorBoundary>
  );
};

export default Routes;
