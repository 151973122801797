import React from 'react';
import '../../styles/footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <div>&copy; Sintellisys 2020</div>
    </div>
  );
};

export default Footer;
