import * as Yup from 'yup';

const addEvent = Yup.object({
  title: Yup.string().required('This field is required.'),
  age: Yup.string().required('This field is required.'),
  offset_time: Yup.string().required('This field is required.'),
  language: Yup.string().required('This field is required.'),
  country: Yup.string().required('This field is required.'),
  artist: Yup.string().required('This field is required.'),
  organiser: Yup.string().required('This field is required.'),
  body: Yup.string().required('This field is required.'),
  currency: Yup.string().required('This field is required.'),
  venue: Yup.string().required('This field is required.'),
  venueAddress: Yup.string().required('This field is required.'),
  startTime: Yup.string().required('This field is required.'),
  endTime: Yup.string().required('This field is required'),
  organiser: Yup.string().required('This field is required'),
  typeOfTicket: Yup.string().required('This field is required'),
  personCapacity: Yup.number()
    .typeError('Value must be a number')
    .integer('Value must be a number')
    .required('This field is required.')
    .min(1, 'Enter valid number')
    .max(10000, 'Enter valid number'),
  price: Yup.number()
    .typeError('Value must be a number')
    .integer('Value must be a number')
    .required('This field is required.')
    .min(1, 'Enter valid number')
    .max(10000, 'Enter valid number'),
  event_list_img_path: Yup.mixed()
    .required('This field is required.')
    .nullable(),
  event_slidder_img_path: Yup.mixed()
    .required('This field is required.')
    .nullable(),
  cover_img_path: Yup.mixed().required('This field is required.').nullable(),
  ticket_banner_img_path: Yup.mixed()
    .required('This field is required.')
    .nullable(),
  eventType: Yup.string().required('This field is required.'),
});

export default addEvent;
