import { GET_AFICIONADO_LIST } from '../actions/aficionadoManagement';

export const INITIAL_STATE = {
  aficionadoList: {},
};

const aficionadoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AFICIONADO_LIST: {
      return {
        aficionadoList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default aficionadoReducer;
