const messages = {
  tryAgain: 'Something went wrong! Please try again later.',
  noData: 'No data available.',
  sessionExpired: 'Your session has expired. Please log in again.',
  forgotPasswordMessage: 'Invalid e-mail address',
  fileUploadedValidation:
    'Uploaded file is not a valid image. Only image file are allowed.',
};

export default messages;
