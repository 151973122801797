import React from 'react';
import ReactPagination from 'react-js-pagination';
import { constants } from '../../constants';

const Pagination = ({
  page,
  totalPages,
  totalItemCount,
  onPageChange,
  pageText,
  color,
  pageTextHidden,
  tabindex,
}) => {
  const { ofPlaceholder, pagePlaceholder } = constants.pagination;
  return (
    <div className={`pagination-row ${color}`} tabIndex={tabindex}>
      {!pageTextHidden && (
        <p className="counter">
          {pagePlaceholder} <span>{page}</span> {ofPlaceholder}{' '}
          <span>{totalPages}</span> {pageText}
        </p>
      )}
      <ReactPagination
        activePage={page}
        itemsCountPerPage={20}
        totalItemsCount={totalItemCount}
        pageRangeDisplayed={3}
        onChange={onPageChange}
        prevPageText="<"
        lastPageText=">>"
        nextPageText=">"
        firstPageText="<<"
      />
    </div>
  );
};

export default Pagination;
