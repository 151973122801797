import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SidebarHeader } from 'react-pro-sidebar';
import { setSidebarVisibility, setActiveMenuName } from '../../actions/sidebar';
import {
  getActiveClass,
  getActiveClassNew,
  getUserRole,
} from '../../utility/common';
import { constants } from '../../constants';
import 'react-pro-sidebar/dist/css/styles.css';
import '../../styles/sidebar.scss';
import { userMapperRole } from '../../utility/mapper';

const Sidebar = ({ location, flag }) => {
  const dispatch = useDispatch();

  const { profile, isCollapsed, isVisible, activeMenuName } = useSelector(
    (state) => ({
      isCollapsed: state.sidebar.isCollapsed,
      isVisible: state.sidebar.isVisible,
      profile: state.profile,
      activeMenuName: state.sidebar.activeMenuName,
    }),
  );

  const toggleSidebar = (value) => dispatch(setSidebarVisibility(value));
  const closeSidebar = () => toggleSidebar(false);

  const { sideMenuMapper } = constants;

  const handleNavigator = (navigator) => {
    dispatch(setActiveMenuName(navigator.name));
  };

  let role = getUserRole(profile);

  return (
    <ProSidebar
      collapsed={isCollapsed}
      breakPoint="lg"
      toggled={isVisible}
      onToggle={toggleSidebar}>
      <SidebarHeader>
        {isCollapsed ? (
          <a href="#">
            <img
              alt="Icon logo"
              className="small-logo"
              src="/images/icon-logo.png"
            />
          </a>
        ) : (
          // <h1>ParGo</h1>
          // <a href="#">
          //   {' '}
          <img alt="Logo" className="brand-logo" src="/images/logo-black.png" />
          // </a>
        )}
        <i className="fas fa-times close-sidebar" onClick={closeSidebar} />
      </SidebarHeader>
      <Menu iconShape="round">
        {sideMenuMapper.map((navigator, index) => {
          return (
            <>
              {role && userMapperRole[role].includes(navigator.name) ? (
                <MenuItem
                  key={index + 1}
                  onClick={() => handleNavigator(navigator)}
                  className={getActiveClassNew(navigator)}
                  icon={<i className={'sidebar-typo ' + navigator.icon} />}>
                  <NavLink
                    onClick={closeSidebar}
                    to={navigator.path}
                    title={navigator.label}>
                    <span className="sidebar-typo">{navigator.label}</span>
                  </NavLink>
                </MenuItem>
              ) : (
                ''
              )}
            </>
          );
        })}
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
