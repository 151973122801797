import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const CustomButton = ({
  label,
  disabled,
  isLoading,
  onClick,
  className,
  type,
  variant,
  iconClass,
  prependIcon,
  tabindex,
}) => {
  return (
    <Button
      variant={variant || 'primary'}
      onClick={onClick}
      disabled={disabled}
      className={className}
      tabIndex={tabindex}
      type={type}
    >
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <>
          {prependIcon && (
            <i className={iconClass} data-testid={'prePendIcon'} />
          )}
          {label}
          {!prependIcon && (
            <i className={iconClass} data-testid={'postPendIcon'} />
          )}
        </>
      )}
    </Button>
  );
};

export default React.memo(CustomButton);
