import { get, put, post } from './client';
import { constants } from '../constants/';

const { contentType } = constants.multipartFormData;

const getProfile = (id) => {
  return get(`/admin/users/${id}`);
};

const getOrganiserProfile = (id) => {
  return get(`/admin/organisers/${id}`);
};

const updateProfile = (id, body) => {
  return put(`/admin/users/${id}`, body, contentType);
};

const changePasswordModel = (body) => {
  return put(`admin/users/change_password`, body);
};

// const addProfiles = (body) => {
//   return post('', body);
// };

export { getProfile, updateProfile, changePasswordModel, getOrganiserProfile };
