import { get, post, put } from './client';

export const getAficionadoList = (body) => {
  return get('/admin/users/?role=aficionados', body);
};

export const getAficionadoSearchList = () => {
  return get('https://mocki.io/v1/f8ae7190-e626-40bd-ba00-f8f9d9da6023');
};

export const addNewAficionado = (body) => {
  return post('https://mocki.io/v1/ff7e8a6f-3ac6-453b-aa15-abb0cc7576bd', body);
};

export const updateAficionadoStatus = (id) => {
  return put(`/admin/users/${id}/update_status`);
};
