import axios from 'axios';
import { messages, constants } from '../constants';
import { getToken, getBaseURL, showToast } from '../utility/common';

// import store from '../redux/store';
const client = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

const { events } = constants;
const { logout } = events;

const get = (url, body, headers = {}) =>
  client.get(url, { params: body, headers: headers });

const post = (url, body, headers = {}) => client.post(url, body, { headers });

const put = (url, body, headers = {}) => client.put(url, body, { headers });

const patch = (url, body, headers = {}) => client.patch(url, body, { headers });

const del = (url, body, headers = {}) =>
  client.delete(url, { params: body, headers: headers });

client.interceptors.request.use(async (config) => {
  config.headers.Authorization = await getToken();
  return config;
});

client.interceptors.response.use(
  function (response) {
    if (response?.data && response?.data?.logout) {
      document.dispatchEvent(logout);
    }
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      if (
        error?.response?.config.url === '/users/sign_in?admin_api=true' ||
        error?.response?.config.url === '/users/password' ||
        error?.response?.config.url === '/users/password?admin_api=true'
      ) {
        showToast(error?.response?.data?.errors);
      } else {
        if (error.response?.data && error.response?.data?.logout) {
          document.dispatchEvent(logout);
        } else {
          showToast(error?.response?.data?.errors);
        }
      }
    } else if (error?.response?.status === 400) {
      showToast(error?.response?.data?.error);
    } else if (error?.response?.status === 422) {
      showToast(
        error?.response?.data?.message ||
          error?.response?.data?.errors ||
          error?.response?.data?.error,
      );
    } else if (error?.response?.status === 404) {
      showToast(error?.response?.data?.message);
    } else if (error?.response?.status === 500) {
      showToast(
        error?.response?.data?.errors &&
          error?.response?.data?.errors[0]?.detail,
      );
    } else {
      showToast(messages.tryAgain);
      return Promise.reject(error);
    }
  },
);

export { get, post, put, del, patch };

export default client;
