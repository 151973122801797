import { GET_EVENT_LIST, GET_EVENT_DATA } from '../actions/eventManagement';

export const INITIAL_STATE = {
  eventList: '',
  editEventData: '',
};

const eventReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EVENT_LIST: {
      return {
        eventList: action.payload,
      };
    }
    case GET_EVENT_DATA: {
      return {
        [action.key]: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default eventReducer;
