import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function AnimatedMulti(props) {
  const {
    id,
    labelText,
    onBlur,
    defaultValue,
    placeholder,
    options,
    error,
    disabled,
    onChange,
    name,
    isMulti,
    closeMenuOnSelect,
    isRequired,
    inputRef,
  } = props;
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={id}>
        {labelText}
        {isRequired && <span className="required">*</span>}
      </label>
      <div className="input-group">
        <Select
          ref={inputRef}
          name={name}
          defaultValue={defaultValue}
          value={defaultValue}
          isDisabled={disabled}
          closeMenuOnSelect={closeMenuOnSelect || false}
          components={animatedComponents}
          isMulti={isMulti}
          options={options}
          onChange={onChange}
          className="react-select-dropdown"
          classNamePrefix="options"
          onBlur={onBlur}
          id={id}
          isSearchable
          isClearable
          placeholder={placeholder}
        />
      </div>
      {error && <div className="error-text">{error}</div>}
    </div>
  );
}
