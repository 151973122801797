import { get, post, put } from './client';

export const getCreatorList = (body, role) => {
  if(role)
    return get(`/admin/users/?role=${role}`, body);
  else
    return get(`/admin/users`, body);
};

export const addNewCreator = (body) => {
  return post('/user/add', body);
};

export const updateCreatorStatus = (id) => {
  return put(`/admin/users/${id}/update_status`);
};
