import { login, logOut } from '../apis/login';
import { messages } from '../constants';
import {
  removeToken,
  saveRole,
  saveToken,
  saveUserId,
  saveUserType,
  showToast,
} from '../utility/common';

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const LOGOUT = 'LOGOUT';

/** This method used to get the token into the API header and save into the local storage*/
export const setUserToken = (body) => (dispatch) => {
  return login(body).then((res) => {
    if (res?.data?.status === true) {
      let token = res?.headers?.authorization;
      saveToken(token);
      saveUserId(res?.data?.data?.user?.id || res?.data?.data?.organiser?.id);
      saveUserType(res?.data?.data?.user?.type || res?.data?.data?.organiser?.type)
      dispatch({
        type: SET_USER_TOKEN,
        payload: token,
      });
    }
    return res?.data;
  });
};

/** This method used for remove token into the local storage*/
export const logout = (flag) => (dispatch) => {
  return logOut().then((res) => {
    if (res?.data?.status) {
      removeToken();
      dispatch({ type: LOGOUT, payload: {} });
      if (flag === true) {
        showToast(res?.data?.message);
      }
    } else {
      showToast(messages.tryAgain);
    }
  });
};

// export const logout = () => {
//   return (dispatch) => {
//     dispatch({ type: LOGOUT, payload: {} });
//   };
// };
