import { get, post, del, put, patch } from './client';
import { constants } from '../constants/';

const { contentType } = constants.multipartFormData;

export const getOrganiserList = (body) => {
  return get('/admin/organisers', body);
};

export const createOrganiser = (body) => {
  return post('/admin/organisers', body, contentType);
};

export const destroyOrganiser = (id) => {
  return del(`/admin/organisers/${id}`);
};

export const getOrganiser = (id) => {
  return get(`/admin/organisers/${id}`);
};

export const updateOrganiser = (id, body) => {
  return put(`/admin/organisers/${id}`, body, contentType);
};

export const updateOrganiserStatus = (id) => {
  return patch(`/admin/organisers/${id}/update_status`);
};
