import { constants } from '../constants';
import { del, get, post, put } from './client';
const { contentType } = constants.multipartFormData;

export const getEventList = (body) => {
  return get('/admin/events', body);
};

export const createEvent = (body) => {
  return post('/admin/events', body, contentType);
};

export const updateEvent = (id, body) => {
  return put(`/admin/events/${id}`, body, contentType);
};

export const destroyEvent = (id) => {
  return del(`/admin/events/${id}`);
};

export const getEvent = (id) => {
  return get(`/admin/events/${id}`);
};
