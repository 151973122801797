import React from 'react';
import { Table } from 'react-bootstrap';
import { Pagination } from '../index';
import { messages } from '../../constants';
import { sortingMethodIconMapper } from '../../utility/mapper';
import { addSortingClassInHeader } from '../../utility/common';
import { constants } from '../../constants';
const CustomTable = ({
  tableClass,
  actions,
  sortMethod,
  headers,
  children,
  pageCount,
  onHeaderClick,
  onPageChange,
  activePage,
  countText,
  totalItemsCount,
  pageTextHidden,
  tabindex,
  sortId,
}) => {
  const { emptyHeader } = constants.tableHeader;

  return (
    <div>
      <p className="total-count">
        Total no. of records:{' '}
        <span tabIndex={'5'}>{totalItemsCount || 0} </span>
      </p>
      <div className="table-parent">
        <Table className={tableClass} hover responsive>
          {/* <thead> */}
          <tr>
            <th>No.</th>
            {headers && headers?.length > 0
              ? headers.map(({ label, sort, id, subLabel }, index) => (
                  <th
                    key={index}
                    className={addSortingClassInHeader('sort')}
                    onClick={() => sort && onHeaderClick(id)}>
                    <span>{label}</span>
                    {subLabel && (
                      <span className="sub-heading">{subLabel}</span>
                    )}
                    {sort ? (
                      <i
                        className={
                          sortId === id
                            ? sortingMethodIconMapper[sortMethod]
                            : sortingMethodIconMapper['both']
                        }
                      />
                    ) : (
                      ''
                    )}
                  </th>
                ))
              : emptyHeader}
            {actions && <th>Actions</th>}
          </tr>
          {/* </thead> */}
          {/* <tbody> */}
          {children || (
            <tr className="no-data-msg">
              <td colSpan={headers && headers.length + 2}>{messages.noData}</td>
            </tr>
          )}
          {/* </tbody> */}
        </Table>
      </div>
      {children && pageCount > 1 && (
        <Pagination
          // tabindex={"6"}
          page={activePage}
          pageText={countText}
          onPageChange={onPageChange}
          totalItemCount={totalItemsCount}
          totalPages={pageCount}
          color="grey"
          pageTextHidden={pageTextHidden}
          tabIndex={tabindex}
        />
      )}
    </div>
  );
};

export default CustomTable;
