import { post, del, patch, get } from './client';
import { constants } from '../constants/';

const { contentType } = constants.multipartFormData;

export const login = (body) => {
  return post('/users/sign_in?admin_api=true', body);
};

export const logOut = () => {
  return del('/users/sign_out');
};

export const forgotPassword = (body) => {
  return post('/users/password?admin_api=true', body);
};

export const resetPassword = (body) => {
  return patch('/users/password', body, contentType);
};

export const setInvitationPassword = (body) => {
  return post('/users/invitation/accept_invite', body);
};

export const checkToken = (body) => {
  return get('/users/valid_password_token', body);
};
