import { getAficionadoSearchList } from '../apis/aficionadoManagement';
export const SET_AUTOSUGGESTION_DATA = 'SET_AUTOSUGGESTION_DATA';

export const searchAficionado = () => (dispatch) => {
  return getAficionadoSearchList().then((res) => {
    if (res?.status === 200) {
      dispatch(setAutoSuggestionData('listOfSearchAficionado', res?.data));
    }
    return res?.data;
  });
};

export const setAutoSuggestionData = (key, data) => ({
  type: SET_AUTOSUGGESTION_DATA,
  key: key,
  payload: data,
});
