import { GET_INVITATION_LIST } from '../actions/invitationManagement';

export const INITIAL_STATE = {
  invitationList: {},
};

const invitationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVITATION_LIST: {
      return {
        invitationList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default invitationReducer;
