export const statusToColorMapper = {
  success: 'green',
  failure: 'red',
  in_progress: 'yellow',
};

export const sortingMethodIconMapper = {
  both: 'fas fa-sort',
  asc: 'fas fa-sort-up',
  desc: 'fas fa-sort-down',
};

export const userMapperRole = {
  Admin: [
    'creator',
    'aficionado',
    'sendInvitation',
    'genre',
    'profile',
    'event',
    'organiser',
  ],
  Aficionado: ['creator', 'aficionado', 'sendInvitation', 'genre'],
  Creator: ['creator', 'aficionado', 'sendInvitation', 'genre'],
  organiser: ["event"]
};

export const sortingMapper = {
  profile_name: 'profile_name',
  email: 'email',
};

export const colorMapperRole = {
  active: 'active',
  inactive: 'inactive',
};
