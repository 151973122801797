import { decrementLoaderCount, incrementLoaderCount } from './loader';
import { getOrganiserList } from '../apis/organiserManagement';

export const GET_ORGANISER_LIST = 'GET_ORGANISER_LIST';
export const GET_ORGANISER_DATA = 'GET_ORGANISER_DATA';

export const getOrganiserListData = (body) => (dispatch) => {
  dispatch(incrementLoaderCount(true));
  return getOrganiserList(body)
    .then((res) => {
      if (res?.data?.status) {
        const eventList = {
          items: (res?.data && res?.data?.data?.organisers) || [],
          totalItemCount: (res?.data && res?.data?.records) || 0,
          totalPages: (res?.data && res?.data?.total_pages) || 0,
        };
        dispatch(setOrganiserList(eventList));
      }
      dispatch(decrementLoaderCount(false));

      return res?.data?.organisers;
    })
    .catch(() => dispatch(decrementLoaderCount(false)));
};

export const setOrganiserList = (data) => ({
  type: GET_ORGANISER_LIST,
  payload: data,
});
